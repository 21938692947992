import React from "react";
import DraggableModal from "../../../../../component/DraggableModal";
import {Button, Col, Form, Input, Row, Skeleton, Space, Transfer} from "antd";
import DepartmentApi from "../../../../../api/user/department";
import AccountApi from "../../../../../api/user/account";
import {ProForm, ProFormText} from "@ant-design/pro-components";

/**
 * 编辑团队
 * @author xuwei
 */
export default class UpdateUserTeamModal extends React.Component {
    state = {
        loading: false,
        loadingTransferData: false,
        teamId:"",
        name: "",
        transferData: [],
        transferTargetKeys: [],
        managerKeys: [],
        userDepartmentId: "",
    };

    formRef = React.createRef();
    draggableRef = React.createRef();

    close = () => {
        this.draggableRef.current.close();
    }
    show = (data) => {
        const {id ,accountIds,name} = data;

        this.setState({teamId: id,transferTargetKeys:accountIds,name},async () => {
            await this.requestTransferData();
            await this.requestTargetKeys();
        });
        this.draggableRef.current.show();

        //间隔两秒
        setTimeout(() => {
            this.formRef.current.setFieldsValue({
                name: name,
            });
        }, 1000);

    }

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    toggleLoadingTransferData = () => {
        this.setState({
            loadingTransferData: !this.state.loadingTransferData
        });
    };

    componentDidMount() {
        let Entity = JSON.parse(localStorage.getItem("Entity"));
        if(Entity.id){
            this.setState({
                userDepartmentId: Entity.id,
            });
        }



    }



    /**
     * 获取所有用户
     */
    requestTransferData = async () => {
        this.toggleLoadingTransferData();
        const {data} = await AccountApi.getDepartmentAccount(this.state.userDepartmentId);
        this.setState({
            transferData: data,
            loadingTransferData: false
        });

    };

    /**
     * 获取暂无单位用户
     */
    getNoDepartmentCustomer = async () => {
        const {data} = await DepartmentApi.NoDepartmentCustomer();
        return data;
    };

    requestTargetKeys = async () => {
        const {data} = await DepartmentApi.getDepartmentApiUser({params: {departmentId: this.state.userDepartmentId}});
        return data;
    };

    handleTransferTargetChange = (nextTargetKeys, direction, targetKeys) => {
        direction === "left" ? this.onRemoveLeft(nextTargetKeys, direction, targetKeys) : this.onRemoveRight(nextTargetKeys, direction, targetKeys);
        this.setState({transferTargetKeys: nextTargetKeys});
        console.log(nextTargetKeys,this.state.managerKeys )
    };

    onRemoveLeft = (allTargetKeys, direction, targetKeys) => {
        this.setState({managerKeys: [...this.state.managerKeys, ...targetKeys]});
    };

    onRemoveRight = (allTargetKeys, direction, targetKeys) => {
        this.setState({
            managerKeys: this.state.managerKeys.filter(item => {
                return targetKeys.indexOf(item) === -1;
            })
        });
    };




    handle = (data) => {

        this.toggleLoading();
        DepartmentApi.updateTeam({
            name: data.name,
            id: this.state.teamId,
            accountIds: this.state.transferTargetKeys
        }).then(res => {
            this.props.onSuccess(res)
            this.toggleLoading();
            this.close();
        })
    }
    render() {
        return <DraggableModal onCancel={this.close} title="编辑团队" ref={this.draggableRef}>
            <Form ref={this.formRef} onFinish={this.handle} layout="vertical">



                <Skeleton active loading={this.state.loadingTransferData}>
                    <Form.Item name="name" label="团队名称">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="设置团队人员">
                        <Transfer dataSource={this.state.transferData}
                                  showSearch
                                  operations={["添加", "移除"]}
                                  titles={["所有用户", "已有用户"]}
                                  listStyle={{width: "100%", height: 320}}
                                  rowKey={record => record.id}
                                  targetKeys={this.state.transferTargetKeys}
                                  render={item => item.name}
                                  onChange={this.handleTransferTargetChange}/>

                    </Form.Item>
                </Skeleton>


                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">确定</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>;
    }
}
