import React, {Component} from 'react';
import {Button, Col, Row, Space} from "antd";
import {ProForm, ProFormSelect, ProFormText} from "@ant-design/pro-components";
import CustomerDepartmentPicker from "../../../../component/customer/department/CustomerDepartmentPicker";
import RemoteApi from "../../../../api/remote/RemoteApi";
import CustomerApi from "../../../../api/customer/customer";
import DraggableModal from "../../../../component/DraggableModal";

/**
 * @author       : AngelBeats
 * @date         : 2023-06-01 16:05:47
 * @description  : 新建远程设备
 */
export default class CreateRemoteDevice extends Component {

    draggableRef = React.createRef();
    formRef = React.createRef();

    state = {
        loading: false,
        customerData: []
    }

    /**
     * 显示
     */
    show = () => {
        this.draggableRef.current.show();
    };

    /**
     * 隐藏
     */
    close = () => {
        this.draggableRef.current.close();
    }

    /**
     * 显示loading
     */
    showLoading = () => this.setState({loading: true});

    /**
     * 隐藏loading
     */
    closeLoading = () => this.setState({loading: false});

    /**
     * 客户单位选择触发
     * @param id
     */
    onChangeDepartment = id => {
        this.requestCustomerData(id);
    }

    /**
     * 查询单位客户
     * @param customerDepartmentId
     */
    requestCustomerData = customerDepartmentId => {
        CustomerApi.list({departmentId: customerDepartmentId}).then(res => {
            this.setState({customerData: res.data.map(item => ({label: item.name, value: item.id}))})
        }).catch(err => {
            console.error("request customer data failed", err)
        })
    }

    /**
     * 新建
     * @param params
     */
    handle = params => {
        this.showLoading();
        RemoteApi.create(params).then(res => {
            this.closeLoading();
            this.close();
            this.props.onSuccess();
        }).catch(err => {
            this.closeLoading();
            console.error("create remote device failed", err)
        })
    }

    render() {
        return (<DraggableModal title="新建远程设备" ref={this.draggableRef} onCancel={this.close}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>

                <ProFormText
                    name="sn"
                    label="设备识别码"
                    placeholder="请输入设备识别码"
                    required
                    rules={[{required: true, message: "请输入设备识别码"}, {pattern: /\S/, message: "不能为空"}]}/>
                <ProFormText
                    name="name"
                    label="设备名称"
                    placeholder="请输入设备名称"
                    required
                    rules={[{required: true, message: "请输入设备名称"}, {pattern: /\S/, message: "不能为空"}]}/>
                <CustomerDepartmentPicker onChange={this.onChangeDepartment}
                                          name="customerDepartment"
                                          rules={[{required: true, message: "请选择客户单位"}]}/>
                <ProFormSelect
                    name="customer"
                    label="客户"
                    placeholder="请选择客户"
                    required
                    showSearch
                    options={this.state.customerData}
                    rules={[{required: true, message: "请选择客户"}, {pattern: /\S/, message: "不能为空"}]}/>

                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">新建</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>)
    }
}
