import React from "react";
import {Button, Col, Row, Space} from "antd";

import DraggableModal from "component/DraggableModal";
import {ProForm, ProFormCascader, ProFormText} from "@ant-design/pro-components";
import MonitoringApi from "../../../../../api/monitoring";

/**
 * 编辑
 * @author xuwei
 */
export default class EditDisplaceModal extends React.Component {

    state = {
        loading: false,
        show: false,

    };

    formRef = React.createRef();
    draggableRef = React.createRef();

    /**
     * 控制对话框显示隐藏
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({editId: id}, this.getInfo);
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    getInfo = () => {
        MonitoringApi.find(this.state.editId).then(response => {
            let params = {
                count: response.data.count,
                ares: []
            };
            params.ares[0] = response.data.province;
            params.ares[1] = response.data.city;
            params.ares[2] = response.data.county;
            this.formRef.current.setFieldsValue(params);
            console.log(response)
        }).catch(error => {
            console.warn("Get customer Info failed", error);
        });
    };

    /**
     * 提交
     */
    handle = () => {
        this.toggleLoading();
        if(this.formRef.current){
            let { ares,count } = this.formRef.current.getFieldsValue();
            let data = {
                count:Number(count),
                id: this.state.editId,
                county: ares[2],
                city: ares[1],
                province: ares[0]
            }
            MonitoringApi.update(data).then(response => {
                if(response.data){
                    this.close();
                    this.props.onSuccess();
                }
            })
        }
    };

    render() {
        return <DraggableModal onCancel={this.close} title="编辑监控区域" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormCascader required rules={[{required: true, message: "请选择所在地区"}]} name="ares"
                                 label="机构区域"
                                 fieldProps={{options: require("asset/location.json"), onChange: this.areaChange}}/>
                <ProFormText name="count" label="现存设备数量" placeholder="请输入数量" required
                             rules={[
                                 {required: true, message: "请输入数量"},
                                 {pattern: /\S/, message: "不能为空"},
                                 {pattern: /^[0-9]*$/, message: "请输入数字"}
                             ]}/>
            </ProForm>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>提交</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }
}
