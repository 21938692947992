import React, {Component} from "react";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import {Button, Dropdown, Menu, Tooltip} from "antd";
import {EditOutlined, EyeOutlined, MoreOutlined, SwapOutlined} from "@ant-design/icons";

import EquipmentListApi from "api/equipment/list";

import CreateEquipmentDrawer from "./drawer/CreateEquipmentDrawer";
import DeleteEquipmentModal from "./modal/DeleteEquipmentModal";
import EditEquipmentDrawer from "./drawer/EditEquipmentDrawer";
import PreviewEquipmentDrawer from "./drawer/PreviewEquipmentDrawer";
import QRCodeModal from "./modal/QRCodeModal";
import EquipmentQrCodeModal from "./modal/EquipmentQrCodeModal";
import {getUser, isAdmin, isAdminDepartment} from "component/SecurityInterceptor";
import MigrationEquipmentDrawer from "./drawer/MigrationEquipmentDrawer";
import style from "../../user/department/index.module.css";
import CustomerApi from "api/customer/customer";
import EventBus from "../../../../utils/eventBus";
import CustomerDepartmentApi from "../../../../api/customer/department";
import RenderFormSelectItem from "../../../../component/RenderFormSelectItem";
import EquipmentTypeApi from "../../../../api/equipment/type";

/**
 * 设备列表
 */
class EquipmentList extends Component {

    state = {
        customersOptions: [],
        customerDepartmentIds: undefined
    };
    createEquipmentRef = React.createRef();
    editEquipmentRef = React.createRef();
    deleteEquipmentRef = React.createRef();
    previewEquipmentRef = React.createRef();
    migrationEquipmentRef = React.createRef();
    QRCodeRef = React.createRef();
    equipmentQRCodeRef = React.createRef();

    onCustomerDepartmentChange = value => {
        this.setState({customerDepartmentIds: value});
        CustomerApi.list({departmentId: value}).then(response => {
            const customerOptions = [];
            if (!!response.data && response.data.length > 0) {
                response.data.forEach((customer) => {
                    customerOptions.push(this.handleCustomerOption(customer));
                });
            }
            this.setState({customersOptions: customerOptions});
            EventBus.emit("_updateDeviceListSearchItem", {customersOptions: customerOptions})
        }).catch(error => {
            console.warn("Get customers failed", error);
        });
    };

    handleCustomerOption = customer => {
        return {
            label: `${customer.name}(${customer.cellphone})`,
            value: customer.id
        };
    };

    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="查看设备" key="preview">
                <Button icon
                        size="small"
                        onClick={() => this.previewEquipmentRef.current.show(entity.id)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="编辑设备" key="edit">
                <Button icon
                        size="small"
                        onClick={() => this.editEquipmentRef.current.show(entity.id)}>
                    <EditOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="迁移设备" key="migration">
                <Button icon
                        size="small"
                        onClick={() => this.migrationEquipmentRef.current.show(entity.id)}>
                    <SwapOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip key="more" title="更多">
                <Dropdown key="more" overlay={this.renderColumnOptionsMenu(entity, action)} trigger={["click"]}>
                    <Button icon size="small"><MoreOutlined/></Button>
                </Dropdown>
            </Tooltip>
        ];
    };

    columns = [
        {
            title: "报修码",
            dataIndex: "id",
            sorter: (a, b) => a.createTime.localeCompare(b.createTime),
            editable: false
        },
        {
            title: "出厂序列号",
            dataIndex: "sn"
        },
        {
            title: "设备名称",
            sorter: (a, b) => a.createTime.localeCompare(b.createTime),
            dataIndex: "name"
        },
        {
            title: "设备型号",
            dataIndex: "model"
        },
        {
            title: "录入人",
            dataIndex: "creator",
            hideInTable: true
        },
        {
            title: "地区",
            dataIndex: "province",
            hideInSearch: true,
            hideInTable: true,
            valueType: "option",
            render: (node, entity) => entity.province === entity.city ? `${entity.province}-${entity.county}` : `${entity.province}-${entity.city}-${entity.county}`
        },
        {
            title: "客户",
            dataIndex: ["customer", "name"],
            hideInSearch: true
        },
        // {
        //     title: "客户单位",
        //     dataIndex: ["customer", "department", "name"],
        //     hideInSearch: true
        // },
        {
            title: "客户单位",
            dataIndex: [ "department", "name"],
            formItemProps: {name: "customerDepartmentIds"},
            valueType: "treeSelect",
            fieldProps: {
                showSearch: false,
                debounce: 500,
                fieldNames: {label: "name", value: "id"},
                treeNodeFilterProp: "name"
            },
            request: () => CustomerDepartmentApi.tree(getUser().departments[0].id).then(res => res.data).catch(() => []),
        },
        // {
        //     title: "客户",
        //     formItemProps: {name: "customerId"},
        //     valueType: "select",
        //     fieldProps: {
        //         showSearch: true,
        //         debounce: 500,
        //         fieldNames: {label: "name", value: "id"},
        //     },
        //     request: () => CustomerApi.list().then(res => res.data).catch(() => []),
        //     hideInTable: true
        // },
        // {
        //     title: "客户",
        //     formItemProps: {name: "customerId"},
        //     dataIndex: "devicetype",
        //     renderFormItem: (_, item) => item.type === "form" ? null : (
        //         <RenderFormSelectItem requestapi={CustomerApi.list} {...item.fieldProps}/>),
        //     hideInTable: true
        // },

        {
            title: "安装时间",
            dataIndex: "installTime",
            sorter: (a, b) => a.createTime.localeCompare(b.createTime),
            hideInSearch: true
        },
        {
            title: "保修时间",
            dataIndex: "warrantyPeriod",
            sorter: (a, b) => a.createTime.localeCompare(b.createTime),
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    renderColumnOptionsMenu = (entity, action) => {
        return <Menu>
            <Menu.Item key="0">
                <span onClick={() => this.equipmentQRCodeRef.current.show(entity.id)}
                      className={style.btn}>打印设备二维码</span>
            </Menu.Item>
            <Menu.Item key="2" danger>
                <span onClick={() => this.deleteEquipmentRef.current.show(entity.id)} className={style.btn}>删除设备</span>
            </Menu.Item>
        </Menu>;
    };

    toolbarRender = () => {
        return [
            <Button key="qr" type="primary" onClick={() => this.QRCodeRef.current.show()}>批量生成二维码</Button>,
            <Button key="create" type="primary" onClick={() => this.createEquipmentRef.current.show()}>新增设备</Button>
        ];
    };

    requestPage = data => {

        console.log(isAdmin() , isAdminDepartment())
        if (this.state.customerDepartmentIds !== undefined) {
            data.customerDepartmentIds = this.state.customerDepartmentIds;
        }
        const customerDepartmentIds = localStorage.getItem("customerDepartmentIds");

        // if (isAdmin() || isAdminDepartment()) {
        //     return this.requestEquipmentList(data);
        // } else {
        //     if (!!customerDepartmentIds) {
        //         data.customerDepartmentIds = customerDepartmentIds;
        //         return this.requestEquipmentList(data);
        //     }
        // }

        return this.requestEquipmentList(data);
    };

    requestEquipmentList = data => {
        return EquipmentListApi.page(data).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.warn("equipment list page", error);
        });
    };

    reload = () => this.actionRef?.reload();

    render() {
        return <PageContainer header={{title: "设备列表", breadcrumb: {}}} extra={this.toolbarRender()}>
            <ProTable headerTitle="设备列表"
                      rowKey="id"
                      defaultSize="small"
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      request={this.requestPage}/>
            <CreateEquipmentDrawer onSuccess={this.reload} ref={this.createEquipmentRef}/>
            <DeleteEquipmentModal onSuccess={this.reload} ref={this.deleteEquipmentRef}/>
            <EditEquipmentDrawer onSuccess={this.reload} ref={this.editEquipmentRef}/>
            <PreviewEquipmentDrawer onSuccess={this.reload} ref={this.previewEquipmentRef}/>
            <QRCodeModal onSuccess={this.reload} ref={this.QRCodeRef}/>
            <EquipmentQrCodeModal onSuccess={this.reload} ref={this.equipmentQRCodeRef}/>
            <MigrationEquipmentDrawer onSuccess={this.reload} ref={this.migrationEquipmentRef}/>
        </PageContainer>;
    }
}

export default EquipmentList;
