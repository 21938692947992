import React from "react";
import moment from "moment";
import {Button, Cascader, DatePicker, Tooltip} from "antd";
import {EyeOutlined} from "@ant-design/icons";
import {ProTable,PageContainer} from "@ant-design/pro-components";

import WorkorderApi from "api/workorder";

import PreviewSmpWorkorderDrawer from "./drawer/PreviewSmpWorkorderDrawer";
import PreviewOpsWorkorderDrawer from "./drawer/PreviewOpsWorkorderDrawer";
import dayjs from "dayjs";

/**
 * 待回访的工单
 *
 * @author DunShun Huang
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class UnfeedbackWorkorderPage extends React.Component {

    state = {
        workorderId: "",
        columnStateMap: {
            priority: {
                show: false
            },
            appointmentTime: {
                show: false
            },
            deadLine: {
                show: false
            }
        }
    };

    deleteRef = React.createRef();
    previewSmpRef = React.createRef();
    previewOpsRef = React.createRef();
    hangRef = React.createRef();
    continueRef = React.createRef();

    actionRef;

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            editable: false,
            hideInSearch: true,
            render: (node, entity) => {
                let time = dayjs(entity.deadLine).format("YYYYMMDD");
                let nowTime = dayjs().locale("zh-cn").format("YYYYMMDD");
                let delay = nowTime - time > 0 && entity.progress === 5;
                return entity.logicType === "smp" ?
                    <Button type="link" danger={entity.progress === 0 || delay}
                            size="small"
                            onClick={() => this.dialog(entity.id, this.previewSmpRef)}>{entity.id}</Button> :
                    <Button type="link" danger={entity.progress === 0 || delay}
                            size="small"
                            onClick={() => this.dialog(entity.id, this.previewOpsRef)}>{entity.id}</Button>;
            }
        },
        {
            title: "服务类型",
            dataIndex: "type",
            valueEnum: require("../assets/WorkorderType").WorkorderType
        },
        {
            title: "工单类型",
            dataIndex: "logicType",
            valueEnum: require("../assets/WorkorderLogicType").default
        },
        {
            title: "客户姓名",
            dataIndex: "clientName"
        },
        {
            title: "客户电话",
            dataIndex: "clientCellphone"
        },
        {
            title: "填单人",
            dataIndex: "creatorName"
        },
        {
            title: "处理人",
            dataIndex: "handlerName",
            hideInSearch: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "计划完成时间",
            dataIndex: "deadLine",
            hideInSearch: true,
            key: "deadLine"
        },
        {
            title: "预约时间",
            dataIndex: "appointmentTime",
            key: "appointmentTime",
            hideInSearch: true
        },
        {
            title: "优先级",
            dataIndex: "priority",
            key: "priority",
            valueEnum: require("../assets/WorkorderPriority").default
        },
        {
            title: "所在区县",
            key: "locate",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps, ...rest}, form) =>
                <Cascader options={require("asset/location.json")} {...fieldProps}/>
        },
        {
            title: "时间选择",
            key: "timer",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps, ...rest}, form) =>
                <DatePicker.RangePicker {...fieldProps} inputReadOnly/>
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    renderColumnOperationPreview = entity => {
        switch (entity.logicType) {
            case "smp":
                return <Tooltip title="查看" key="preview">
                    <Button icon size="small" onClick={() => this.dialog(entity.id, this.previewSmpRef)}>
                        <EyeOutlined/>
                    </Button>
                </Tooltip>;
            case "ops":
                return <Tooltip title="查看" key="preview">
                    <Button icon size="small" onClick={() => this.dialog(entity.id, this.previewOpsRef)}>
                        <EyeOutlined/>
                    </Button>
                </Tooltip>;
            default:
                return null;
        }
    };

    renderColumnOperations = (node, entity, index, action) => this.renderColumnOperationPreview(entity);

    dialog = (id, ref) => ref.current.show(id);

    renderToolbar = () => [];

    /**
     * 提交回访工单
     * @param params 提交数据
     */
    requestPage = params => {
        params.feedbackScore = 0;
        params.progress = 17;
        if (!!params.locate) {
            params.province = params.locate[0];
            params.city = params.locate[1];
            params.county = params.locate[2];
            params.locate = undefined;
        }
        if (!!params.timer) {
            params.startDate = dayjs(params.timer[0]).format("YYYY-MM-DD");
            params.endDate = dayjs(params.timer[1]).format("YYYY-MM-DD");
            params.timer = undefined;
        }
        return WorkorderApi.page(params).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.error("Find workorder page failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    reload = () => this.actionRef.reload();

    onColumnStateMapChange = columnStateMap => this.setState({columnStateMap});

    render() {
        return <PageContainer header={{title: "待回访工单", breadcrumb: {}}}>
            <ProTable options={{reload: true, setting: true, density: true}}
                      defaultSize="small"
                      rowKey="id"
                      columnsState={this.state.columnStateMap}
                      onColumnsStateChange={this.onColumnStateMapChange}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      request={this.requestPage}
                      toolBarRender={this.renderToolbar}/>

            <PreviewSmpWorkorderDrawer onSuccess={this.reload} ref={this.previewSmpRef}/>
            <PreviewOpsWorkorderDrawer onSuccess={this.reload} ref={this.previewOpsRef}/>
        </PageContainer>;
    }
}
