const RemoteApi = {
    /**
     * 分页查询
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    page: params => global.axios.get("/resource/ahri/ahri/page", {params}),

    /**
     * 查询远程设备信息
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    find: id => global.axios.get(`/resource/ahri/ahri/${id}`),

    /**
     * 新建
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: params => global.axios.post("/resource/ahri", params),

    /**
     * 更新
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    update: params => global.axios.patch(`/resource/ahri/ahri/${params.id}`, params),

    /**
     * 删除
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    remove: id => global.axios.delete(`/resource/ahri/ahri/${id}`),

    /**
     * 连接设备
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    connectDevice: id => global.axios.get(`/resource/ahri/ahri/remote/${id}`)

}

export default RemoteApi;
