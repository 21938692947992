import React, {Component} from "react";
import {Button, Drawer, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {ProTable} from "@ant-design/pro-components";

import EquipmentRepositoryApi from "api/equipment/repository";

import DeleteArticleModal from "../../repository/modal/DeleteArticleModal";
import PreviewArticleDrawer from "../../repository/drawer/PreviewArticleDrawer";
import EditArticleDrawer from "../../repository/drawer/EditArticleDrawer";

/**
 *查看当前设备类型知识库
 * 根据id 获取对应的知识库列表信息
 */
export default class PreviewKnowledgeArticleDrawer extends Component {

    state = {
        deviceTypeId: "",
        drawerWidth: 600,
        loading: false,
        show: false,
        equipment: {
            files: []
        }
    };

    columns = [
        {
            title: "ID",
            hideInSearch: true,
            hideInTable: true,
            dataIndex: "id",
            editable: false
        },
        {
            title: "设备类型",
            dataIndex: ["devicetype", "name"],
            hideInSearch: true
        },
        {
            title: "类型型号",
            dataIndex: ["devicetype", "model"],
            hideInSearch: true
        },
        {
            title: "制造商名称",
            dataIndex: ["manufacturer", "name"],
            hideInSearch: true
        },
        {
            title: "文章标题",
            dataIndex: "title",
            render: (node, entity, index, action) => <p dangerouslySetInnerHTML={{__html: entity.solution}}/>
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    previewArticleRef = React.createRef();
    editArticleRef = React.createRef();
    deleteArticleRef = React.createRef();

    show = id => {
        this.setState({
            deviceTypeId: id,
            show: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        });
    };

    close = () => this.setState({show: false});

    requestPage = data => {
        data.devicetype = this.state.deviceTypeId;
        return EquipmentRepositoryApi.page(data).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(err => {
            return {data: [], success: true, total: 0};
        })
    };

    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="查看文章" key="preview">
                <Button icon
                        size="small"
                        onClick={() => this.previewArticleRef.current.show(entity)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="编辑文章" key="edit">
                <Button icon
                        size="small"
                        onClick={() => this.editArticleRef.current.show(entity.id)}>
                    <EditOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="删除文章" key="delete">
                <Button icon
                        danger
                        size="small"
                        onClick={() => this.deleteArticleRef.current.show(entity.id)}>
                    <DeleteOutlined/>
                </Button>
            </Tooltip>
        ];
    };

    reload = () => this.actionRef?.reload();

    render() {
        return <Drawer title="查看当前类型知识库文章" width={this.state.drawerWidth} open={this.state.show}
                       destroyOnClose
                       closable={!this.state.loading}
                       onClose={this.close}>

            <ProTable headerTitle="知识库"
                      rowKey="id"
                      defaultSize="small"
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      request={this.requestPage}/>
            <DeleteArticleModal ref={this.deleteArticleRef} onSuccess={this.reload}/>
            <PreviewArticleDrawer ref={this.previewArticleRef} onSuccess={this.reload}/>
            <EditArticleDrawer ref={this.editArticleRef} onSuccess={this.reload}/>
        </Drawer>;
    }
}

