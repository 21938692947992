import React, {Component} from 'react';
import {PageContainer, ProTable} from "@ant-design/pro-components";
import {Button, message, Tooltip} from "antd";
import {ApiOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import RemoteApi from "../../../api/remote/RemoteApi";
import CreateRemoteDevice from "./modal/CreateRemoteDevice";
import UpdateRemoteDevice from "./modal/UpdateRemoteDevice";
import DeleteRemoteDevice from "./modal/DeleteRemoteDevice";
import PreviewRemoteDevice from "./modal/PreviewRemoteDevice";
import RemoteLoading from "./modal/RemoteLoading";

/**
 * @author       : AngelBeats
 * @date         : 2023-06-01 15:38:15
 * @description  : 远程列表
 */
export default class RemotePage extends Component {

    actionRef = null

    createRef = React.createRef();
    previewRef = React.createRef();
    editRef = React.createRef();
    deleteRef = React.createRef();
    connectRef = React.createRef();
    remoteLoadingRef = React.createRef();

    columns = [
        {
            title: "设备ID",
            dataIndex: "id",
            hideInSearch: true,
            editable: false
        },
        {
            title: "设备识别码",
            dataIndex: "sn",
            editable: false
        },
        {
            title: "设备名称",
            dataIndex: "name",
            editable: false
        },
        {
            title: "客户",
            dataIndex: ["customerObj", "name"],
            hideInSearch: true,
            editable: false
        },
        {
            title: "客户单位",
            dataIndex: ["customerDepartmentObj", "name"],
            hideInSearch: true,
            editable: false
        },
        {
            title: "详细地址",
            dataIndex: ["customerDepartmentObj", "address"],
            hideInSearch: true,
            editable: false
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ]

    /**
     * 表格操作项
     * @param node
     * @param entity
     * @param index
     * @param action
     */
    renderColumnOperations = (node, entity, index, action) => [
        <Tooltip title="查看" key="preview">
            <Button icon size="small" onClick={() => this.previewRef.current.show(entity.id)}>
                <EyeOutlined/>
            </Button></Tooltip>,
        <Tooltip title="编辑" key="edit">
            <Button icon size="small" onClick={() => this.editRef.current.show(entity.id)}>
                <EditOutlined/>
            </Button></Tooltip>,
        <Tooltip title="连接" key="connect">
            <Button icon size="small" onClick={() => this.connectDevice(entity)}>
                <ApiOutlined/>
            </Button>
        </Tooltip>,
        <Tooltip title="删除" key="delete">
            <Button danger icon size="small" onClick={() => this.deleteRef.current.show(entity.id)}>
                <DeleteOutlined/>
            </Button>
        </Tooltip>,
    ];

    /**
     * 开启连接
     * @param item
     */
    connectDevice = (item) => {
        this.remoteLoadingRef.current.show();
        RemoteApi.connectDevice(item.id).then(res => {
            const parse = JSON.parse(res.data);
            window.open(`/resource/demo-sunlogin-sdk.html?address=${parse.addr}&cid=${parse.sid}`);
            this.remoteLoadingRef.current.close();
        }).catch(err => {
            message.error("连接失败！");
            this.remoteLoadingRef.current.close();
            console.error("connect device failed", err)
        })
    }

    /**
     * 请求列表数据
     * @param params
     */
    requestPage = params => RemoteApi.page(params).then(response => {
        return {data: response.data.records, success: true};
    }).catch(error => {
        console.warn("Get remote device list failed", error);
        return {data: [], success: true, total: 0};
    });

    /**
     * 渲染顶部按钮
     * @returns {JSX.Element[]}
     */
    renderToolbar = () => [
        <Button key="create" type="primary" onClick={() => this.createRef.current.show()}>新建设备</Button>
    ]

    /**
     * 表格页刷新
     * @returns {*}
     */
    reload = () => this.actionRef?.reload();

    render() {
        return (<PageContainer header={{title: "智能运维", breadcrumb: {}}} extra={this.renderToolbar()}>
            <ProTable
                headerTitle="控制设备信息"
                rowKey="id"
                defaultSize="small"
                options={{reload: true, setting: true, density: true}}
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                request={this.requestPage}/>

            <CreateRemoteDevice ref={this.createRef} onSuccess={this.reload}/>
            <UpdateRemoteDevice ref={this.editRef} onSuccess={this.reload}/>
            <DeleteRemoteDevice ref={this.deleteRef} onSuccess={this.reload}/>
            <PreviewRemoteDevice ref={this.previewRef} onSuccess={this.reload}/>
            <RemoteLoading ref={this.remoteLoadingRef}/>
        </PageContainer>)
    }
}
