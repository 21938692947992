import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Button, Col, Drawer, Form, Row, Space} from "antd";
import {ProForm, ProFormDigit, ProFormRadio, ProFormSelect, ProFormText} from "@ant-design/pro-components";

import WangEditor from "component/editor/WangEditor";
import FileUploadRender from "component/FileUploadRender";
import VersionApi from "api/user/version";

const CreateVersionUpdateDrawer = (props, ref) => {

    const formRef = useRef(null);
    const WangEditorRef = useRef(null);
    const filesRef = useRef(null);

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isUploadApk, setIsUploadApk] = useState(true);

    useImperativeHandle(ref, () => ({
        show
    }));

    const show = () => setVisible(true);

    const close = () => {
        setVisible(false);
        setLoading(false);
    };

    const toggleLoading = () => setLoading(!loading);

    /**
     * 平台改变
     * @param value
     */
    const platformOnChange = (value) => value === "android" ? setIsUploadApk(true) : setIsUploadApk(false);

    /**
     * 上传状态
     * @param list
     */
    const uploadOnChange = (list) => {
        console.log(list)
        if (list?.fileList[0].status === "uploading") {
            setLoading(true);
        } else {
            setLoading(false);
        }
    };

    /**
     * 创建版本更新
     * @param data
     */
    const handle = data => {
        if (!WangEditorRef.current.getValue()) {
            formRef.current.setFields([{name: "description", errors: ["请输入描述信息"]}]);
            return;
        }
        if (filesRef.current !== null && filesRef.current.getFilesValue().length === 0 && isUploadApk) {
            formRef.current.setFields([{name: "path", errors: ["请上传安装包"]}]);
            return;
        }
        data.description = WangEditorRef.current.getValue();
        if (isUploadApk) {
            data.path = filesRef.current.getFilesValue()[0];
        }
        toggleLoading();
        VersionApi.create(data).then(res => {
            toggleLoading();
            props.onSuccess();
            close();
        }).catch(err => {
            setLoading(false);
            console.error("Create version update failed", err);
        });
    };

    return (<Drawer title="版本更新" open={visible} onClose={close} destroyOnClose
                    width={window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55}>
        <ProForm formRef={formRef} onFinish={handle} layout={"vertical"} onReset={close}
                 submitter={{
                     resetButtonProps: {style: {display: "none"}},
                     submitButtonProps: {style: {display: "none"}}
                 }}
                 autoFocusFirstInput={false}>

            <ProFormText name="versionName" label="版本名称" rules={[{required: true}]}/>
            <ProFormDigit
                label="版本序号"
                name="versionSerial"
                min={1}
                fieldProps={{precision: 0}}
                rules={[{required: true}]}/>
            <ProFormSelect name="platform" label="平台" rules={[{required: true}]}
                           options={[{label: "安卓", value: "android"}, {label: "apple", value: "ios"}, {
                               label: "web",
                               value: "web"
                           }]}
                           fieldProps={{onChange: platformOnChange}}/>
            <ProFormRadio.Group name="necessary" label="是否强制更新" rules={[{required: true}]} initialValue={1}
                                options={[{label: "是", value: 1}, {label: "否", value: 0}]}/>
            {
                isUploadApk ?
                    <FileUploadRender name="path" label="下载地址" uploadPath="/general/workorder/unassigned/file" required
                                      uploadProps={{maxCount: 1, accept: ".apk"}}
                                      ref={filesRef} onChange={uploadOnChange}/> :
                    <ProFormText name="path" label="下载地址" rules={[{required: true}]}/>
            }
            <Form.Item name="description" label="描述" required>
                <WangEditor ref={WangEditorRef}/>
            </Form.Item>
            <Row justify="end">
                <Col>
                    <Space>
                        <Button disabled={loading} onClick={close}>取消</Button>
                        <Button loading={loading} type="primary" htmlType="submit">更新</Button>
                    </Space>
                </Col>
            </Row>
        </ProForm>
    </Drawer>);
};

export default forwardRef(CreateVersionUpdateDrawer);
