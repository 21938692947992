const EquipmentMapApi = {
    /**
     * 获取各省份设备
     */
    province: () => global.axios.get("/resource/device/count/province"),
    /**
     * 获取市数据
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    queryCity: (data = "") => global.axios.get("/resource/device/count/city", {params: {province: data}}),
    /**
     * 获取区县设备信息
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    queryCounty: (data) => global.axios.get(`/resource/device/count/county`, {params: {city: data}}),
    /**
     * 查询指定区县内的设备经纬度
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    queryCountyInside: (data) => global.axios.get(`/resource/device/lnglats`, {params: {...data}}),
    /**
     * 获取指定经纬度下的设备
     */
    getLnglatEquipment: (data) => global.axios.get("/resource/devices/page", {params: data})

};

export default EquipmentMapApi;
