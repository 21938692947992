import React from "react";
import {Button, Col, Row, Space} from "antd";
import {ProForm, ProFormCascader, ProFormText, ProFormTreeSelect} from "@ant-design/pro-components";

import AccountApi from "api/user/account";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";
import DepartmentApi from "../../../../../api/user/department";
import {getUser} from "../../../../../utils/securityCheck";
import {hasAnyRole} from "../../../../../component/SecurityInterceptor";

/**
 * 新建账号对话框
 * 填写表单对应信息 调用新建账户接口  新建账户
 */
export default class CreateAccountModal extends React.Component {

    state = {
        show: false,
        loading: false
    };

    formRef = React.createRef();
    draggableRef = React.createRef();


    show = () => {
        this.draggableRef.current.show();
    };

    close = () => {
        this.setState({
            loading: false
        });
        this.draggableRef.current.close();
    };

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    handle = (data) => {
        if (data.confirm_password !== data.password) {
            this.formRef.current.setFields([{name: "password", errors: ["两次密码不一致"]}, {
                name: "confirm_password",
                errors: ["两次密码不一致"]
            }]);
            return;
        }
        if (!!this.props.departmentId) {
            data.department = this.props.departmentId;
        }
        data.province = data.ares[0];
        data.city = data.ares[1];
        data.county = data.ares[2];
        // 在机构管理下创建账号 默认工程师角色
         if (hasAnyRole(["user-department-manager"])){
            data.roleIds = ['3'];
         }
        this.toggleLoading();
        AccountApi.create(data).then((response) => {
            console.info("create account success", response);
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            if (error.status === 40001) {
                this.formRef.current.setFields([{name: "username", errors: ["该用户名已存在"]}]);
            }
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            }
            console.warn("create account failed", error);
        });
    };

    trigger = () => {
        return <Button type="primary" onClick={this.show}>新建账户</Button>;
    };



    dialog = () => {
        return <DraggableModal onCancel={this.close} title="新建账户" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormCascader required rules={[{required: true, message: "请选择所在地区"}]} name="ares" label="区域"
                                 fieldProps={{options: require("asset/location.json")}}/>
                <ProFormTreeSelect required rules={[{required: true, message: "请选择机构"}]} name="department"
                                   label="机构"
                                   allowClear
                                   secondary
                                   request={async () => DepartmentApi.findDepartmentTree(getUser().departments[0].id).then(res => res.data).catch(() => [])}
                                   fieldProps={{
                                       showArrow: false,
                                       filterTreeNode: true,
                                       showSearch: true,
                                       treeNodeFilterProp: "title",
                                       fieldNames: {label: "name", value: "id"},
                                   }}/>
                <ProFormText name="username" label="用户名" placeholder="请输入用户名" required
                             rules={[{required: true, message: "请输入用户名"}, {
                                 pattern: /^[0-9a-zA-Z]{5,20}$/g,
                                 message: "请输入5-20个英文字母或数字"
                             }]}/>
                <ProFormText name="name" label="姓名" placeholder="请输入姓名" required
                             rules={[{required: true, message: "请输入姓名"}, {pattern: /\S/, message: "不能为空"}]}/>
                <ProFormText name="cellphone" label="手机号" placeholder="请输入手机号" required
                             rules={[{required: true, message: "请输入手机号"}, {
                                 pattern: /^1[3456789]\d{9}$/g,
                                 message: "请输入正确的手机号"
                             }]}/>
                <ProFormText name="email" label="邮箱" placeholder="请输入邮箱"
                             rules={[{
                                 pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g,
                                 message: "请输入正确的邮箱"
                             }]}/>
                <ProFormText.Password label="密码" name="password" required placeholder="请输入密码"
                                      rules={[{required: true, message: "请输入密码"}]} maxLength="20" minLength="6"/>
                <ProFormText.Password label="确认密码" name="confirm_password" required placeholder="请确认密码"
                                      rules={[{required: true, message: "确认密码"}]} maxLength="20" minLength="6"/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">新建</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    };

    render() {
        return <>
            {this.trigger()}
            {this.dialog()}
        </>;
    }
}
