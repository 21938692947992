import {objToUrlParams} from "../../utils/tool";

const WorkorderApi = {
    /**
     * 查询工单
     * @param id 工单 ID
     */
    find: (id) => global.axios.get(`/workorder/workorder/${id}`),
    /**
     * 分页查询工单
     */
    // page: (params) => global.axios.get("/workorder/workorders/page", {params}),
    //数组参数
    page: (params) => {
        let {abnormalStatus} = params;
        let AbnormalStatus = ``
        if(!abnormalStatus){
            return global.axios.get(`/workorder/workorders/page`,{params});
        }else{
            abnormalStatus.forEach((item) => {AbnormalStatus += `&abnormalStatus=${item}`})
            delete params.abnormalStatus
            let paramUrl = objToUrlParams(params);
            return global.axios.get(`/workorder/workorders/page?${paramUrl}${AbnormalStatus}`);
        }
    },

    /**
     * 创建工单
     */
    create: (data) => global.axios.post("/workorder/unassigned-workorder", data),
    /**
     * 更新工单
     */
    update: (data) => global.axios.patch("/workorder/workorder", data),
    /**
     * 指派工单
     */
    dispatch: (id, data) => global.axios.post(`/workorder/unassigned-workorder/${id}/handler`, data),
    /**
     * 接收工单
     */
    receive: (data) => global.axios.patch("/workorder/workorder/receive", data),
    /**
     * 转发工单
     */
    forward: (data) => global.axios.patch("/workorder/workorder/forward", data),
    /**
     * 完成工单
     */
    complete: (data) => global.axios.patch("/workorder/workorder/complete", data),
    /**
     * 关闭工单
     */
    close: (data) => global.axios.patch("/workorder/workorder/close", data),
    /**
     * 驳回工单
     */
    denied: (data) => global.axios.patch("/workorder/workorder/denied", data),
    /**
     * 删除工单
     * @param id 客户单位 ID
     */
    delete: (id) => global.axios.delete(`/workorder/workorder/${id}`),
    /**
     * 删除团队工单
     */
    deleteTeam: (id) => global.axios.delete(`/workorder/team-workorder/${id}`),
    /**
     * 批量删除工单
     */
    deletes: (departmentIds) => global.axios.delete("/workorder/workorders", {data: {departmentIds}}),

    /**
     * 开始工单
     * @param data 表单数据
     */
    start: (data) => global.axios.patch("/workorder/workorder/start", data),

    /**
     * 挂起工单
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    hang: (data) => global.axios.patch("/workorder/workorder/hangup", data),

    visit: (data) => global.axios.patch("/workorder/workorder/feedback", data),

    /**
     * 获取指标数量
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    indicators: (data) => global.axios.get("/workorder/workorder/statistics/count", {params: data}),

    /**
     * 获取报修池未转换工单数量
     * @returns {Promise<AxiosResponse<any>>}
     */
    repairCount: () => global.axios.get("/workorder/prepare-workorder/count", {params: {converted: 0}}),

    /**
     * 获取待指派工单中待指派的数量
     * @returns {Promise<AxiosResponse<any>>}
     */
    dispatchCount: () => global.axios.get("/workorder/unassigned-workorder/count", {params: {converted: 0}}),
    /**
     * 获取团指派工单中待指派的数量
     */
    teamDispatchCount: (teamIds) => global.axios.get(`/workorder/team-workorder/count?converted=0${teamIds}`),
    /**
     * 查询待指派工单数据
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDispatch: id => global.axios.get(`/workorder/unassigned-workorder/${id}`),

    /**
     * 更新待指派工单
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateDispatch: data => global.axios.patch("workorder/unassigned-workorder", data),
    /**
     * 更新团队待指派工单
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateTeamDispatch: data => global.axios.patch("/workorder/team-workorder", data),
    /**
     * 团队成员接单
     */
    teamMemberReceive: (data) => global.axios.post("/workorder/team-workorder/handler", data),

    /**
     * 更新设备
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateEquipment: data => global.axios.patch("/workorder/workorder/resource", data),
    /**
     * 查询指定设备是否有正在进行的工单
     * @param resourceId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findEquipmentWorkorder: resourceId => global.axios.get(`/workorder/unassigned-workorder/creatable`, {params: {resourceId}}),

    /**
     * 查询用户工单好评率
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderGoodReview: params => global.axios.get("/workorder/workorder/statistics/degree-of-satisfaction", {params}),

    /**
     * 获取工单数量
     * @param params
     */
    findWorkorderNumber: params => global.axios.get("/workorder/workorder/statistics/count", {params}),

    /**
     * 查询用户正在进行中的工单
     * @param accountId
     */
    ongoingWorkorder: accountId => global.axios.get(`/workorder/account/${accountId}/progressing-workorder`),
    /**
     * 查询团队待指派工单列表
     * @param params
     * @param teamIds
     * @returns {Promise<AxiosResponse<any>>}
     */
    findTeamDispatch: (params,teamIds) => {
        // return global.axios.get(`/workorder/team-workorder/page`,{params});
        let {current, pageSize, converted,teamId} = params;
        //return global.axios.get(`/workorder/team-workorders/page?current=${current}&pageSize=${pageSize}&converted=${converted}${teamIds}`);
        if(teamId){
            return global.axios.get(`/workorder/team-workorders/page`,{params});
        }else{

            return global.axios.get(`/workorder/team-workorders/page?current=${current}&pageSize=${pageSize}&converted=${converted}${teamIds}`);
        }
    },
    /**
     * 查询团队待指派工单详情
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    findTeamDispatchDetail: id => global.axios.get(`/workorder/team-workorder/${id}`),
    /**
     * 查询工单提醒
     * @param data
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    findhint : (data) => global.axios.get("/workorder/workorders/hint",{params:data}),
};

export default WorkorderApi;
