import React from "react";
import moment from "moment";
import {Button, Cascader, Col, DatePicker, Row, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, PauseCircleOutlined, PlayCircleOutlined} from "@ant-design/icons";
import {PageContainer, ProTable, StatisticCard} from "@ant-design/pro-components";

import SecurityInterceptor, {getUser, hasAnyRole} from "component/SecurityInterceptor";
import WorkorderApi from "api/workorder";

import PreviewSmpWorkorderDrawer from "./drawer/PreviewSmpWorkorderDrawer";
import PreviewOpsWorkorderDrawer from "./drawer/PreviewOpsWorkorderDrawer";

import DeleteWorkorderModal from "./modal/DeleteWorkorderModal";

import HangUpWorkorderDialog from "./modal/bussiness/HangUpWorkorderDialog";
import ContinueWorkorderDialog from "./modal/bussiness/ContinueWorkorderDialog";
import UpdateSmpWorkorderDrawer from "./drawer/UpdateSmpWorkorderDrawer";
import UpdateOpsWorkorderDrawer from "./drawer/UpdateOpsWorkorderDrawer";
import dayjs from "dayjs";

/**
 * 我处理的工单
 *
 * @description 我处理的工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class TheWorkorderIHandlePage extends React.Component {

    state = {
        columnStateMap: {
            priority: {
                show: false
            },
            appointmentTime: {
                show: false
            },
            deadLine: {
                show: false
            }
        },
        totalFlag: true,
        receiveFlag: true,
        handleFlag: true,
        handleFlaging: true,
        hangFlag: true,
        finishFlag: true,
        workorderTotal: 0,
        workorderReceive: 0,
        workorderHandle: 0,
        workorderHandleing: 0,
        workorderHang: 0,
        workorderFinish: 0
    };

    deleteRef = React.createRef();
    previewSmpRef = React.createRef();
    previewOpsRef = React.createRef();
    hangRef = React.createRef();
    continueRef = React.createRef();
    editSmpRef = React.createRef();
    editOpsRef = React.createRef();

    actionRef;

    componentDidMount() {
        this.getIndicatorsData();
    }

    renderOfColumnOperationWhenSmp = (entity) => {
        return <Space>
            <Tooltip title="查看">
                <Button icon size="small" onClick={() => this.dialog(entity.id, this.previewSmpRef)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
            <Tooltip title="编辑">
                <Button icon size="small"
                        onClick={() => this.dialog(entity.id, this.editSmpRef)}
                        disabled={entity.progress === 13 || entity.progress === 17}>
                    <EditOutlined/>
                </Button>
            </Tooltip>
            {this.renderColumnOperationHangup(entity)}
            <SecurityInterceptor key="delete" role={["admin", "customer-server"]} permission={["workorder:delete"]}>
                <Tooltip title="删除">
                    <Button danger icon size="small" onClick={() => this.dialog(entity.id, this.deleteRef)}
                            disabled={entity.progress === 13 || entity.progress === 17}>
                        <DeleteOutlined/>
                    </Button>
                </Tooltip>
            </SecurityInterceptor>
        </Space>;
    };

    renderOfColumnOperationWhenOps = (entity) => {
        return <Space>
            <Tooltip title="查看">
                <Button icon size="small" onClick={() => this.dialog(entity.id, this.previewOpsRef)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
            <Tooltip title="编辑">
                <Button icon size="small" onClick={() => this.dialog(entity.id, this.editOpsRef)}
                        disabled={entity.progress === 13 || entity.progress === 17}>
                    <EditOutlined/>
                </Button>
            </Tooltip>
            {this.renderColumnOperationHangup(entity)}
            <SecurityInterceptor key="delete" role={["admin", "customer-server"]} permission={["workorder:delete"]}>
                <Tooltip title="删除">
                    <Button danger icon size="small" onClick={() => this.dialog(entity.id, this.deleteRef)}
                            disabled={entity.progress === 13 || entity.progress === 17}>
                        <DeleteOutlined/>
                    </Button>
                </Tooltip>
            </SecurityInterceptor>
        </Space>;
    };

    /**
     * 工单挂起操作
     * @param entity
     * @returns {JSX.Element|null}
     */
    renderColumnOperationHangup = entity => {
        switch (entity.progress) {
            case 13:
                return <Tooltip title="挂起" key="hang">
                    <Button icon size="small" onClick={() => this.hangup(entity.id)}>
                        <PauseCircleOutlined/>
                    </Button>
                </Tooltip>;
            case 14:
                return <Tooltip title="继续" key="next">
                    <Button icon size="small" onClick={() => this.continue(entity.id)}>
                        <PlayCircleOutlined/>
                    </Button>
                </Tooltip>;
            default:
                return null;
        }
    };

    hangup = id => this.hangRef.current.show(id);

    continue = id => this.continueRef.current.show(id);

    renderColumnOperations = (node, entity, index, action) => {
        return entity.logicType === "smp" ? this.renderOfColumnOperationWhenSmp(entity) : this.renderOfColumnOperationWhenOps(entity);
    };

    dialog = (id, ref) => ref.current.show(id);

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            copyable: true,
            editable: false,
            render: (node, entity) => {
                return entity.logicType === "smp" ?
                    <Button type="link" danger={entity.delay === 1 }
                            size="small"
                            onClick={() => this.dialog(entity.id, this.previewSmpRef)}>{entity.id}</Button> :
                    <Button type="link" danger={entity.delay === 1 }
                            size="small"
                            onClick={() => this.dialog(entity.id, this.previewOpsRef)}>{entity.id}</Button>;
            }
            // render: (node, entity) => {
            //     let time = dayjs(entity.deadLine).format("YYYYMMDD");
            //     let nowTime = dayjs().locale("zh-cn").format("YYYYMMDD");
            //     let delay = nowTime - time > 0 && entity.progress === 5;
            //     return entity.logicType === "smp" ?
            //         <Button type="link" danger={entity.progress === 0 || delay}
            //                 size="small"
            //                 onClick={() => this.dialog(entity.id, this.previewSmpRef)}>{entity.id}</Button> :
            //         <Button type="link" danger={entity.progress === 0 || delay}
            //                 size="small"
            //                 onClick={() => this.dialog(entity.id, this.previewOpsRef)}>{entity.id}</Button>;
            // }
        },
        {
            title: "处理进度",

            dataIndex: "progress",
            valueEnum: require("../assets/WorkorderProgress").default
        },
        {
            title: "服务类型",
            dataIndex: "type",
            valueEnum: require("../assets/WorkorderType").WorkorderType
        },
        {
            title: "工单类型",

            dataIndex: "logicType",
            valueEnum: require("../assets/WorkorderLogicType").default
        },
        {
            title: "客户姓名",

            dataIndex: "clientName"
        },
        {
            title: "客户电话",
            dataIndex: "clientCellphone"
        },
        {
            title: "填单人",

            dataIndex: "creatorName"
        },
        // {
        //     title: "处理人",
        //     dataIndex: "handlerName",
        //     hideInSearch: true
        // },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "计划完成时间",
            dataIndex: "deadLine",
            hideInSearch: true,
            key: "deadLine"
        },
        {
            title: "预约时间",
            dataIndex: "appointmentTime",
            key: "appointmentTime",
            hideInSearch: true
        },
        {
            title: "优先级",
            dataIndex: "priority",

            key: "priority",
            valueEnum: require("../assets/WorkorderPriority").default
        },
        {
            title: "满意度",

            dataIndex: "feedbackScore",
            hideInTable: !hasAnyRole(["customer-service", "admin"]),
            hideInSearch: !hasAnyRole(["customer-service", "admin"]),
            valueEnum: require("../assets/WorkorderFeedbackType").default
        },
        {
            title: "所在区县",
            key: "locate",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps, ...rest}, form) =>
                <Cascader options={require("asset/location.json")} {...fieldProps}/>
        },
        {
            title: "时间选择",
            key: "timer",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps, ...rest}, form) =>
                <DatePicker.RangePicker {...fieldProps} inputReadOnly/>
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    renderToolbar = () => [];

    /**
     * 请求表格信息
     * @param params
     */
    requestPage = params => {
        let userInfo = getUser();
        params.handlerId = userInfo.id;
        if (!!params.locate) {
            params.province = params.locate[0];
            params.city = params.locate[1];
            params.county = params.locate[2];
            params.locate = undefined;
        }
        if (!!params.timer) {
            params.startDate = moment(params.timer[0]).format("YYYY-MM-DD");
            params.endDate = moment(params.timer[1]).format("YYYY-MM-DD");
            params.timer = undefined;
        }
        return WorkorderApi.page(params).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.error("Find workorder page failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    reload = () => {
        this.getIndicatorsData();
        this.actionRef.reload();
    };
    onLoadingChange = () => {
        this.getIndicatorsData();
    };

    onColumnStateMapChange = columnStateMap => this.setState({columnStateMap});

    /**
     * 获取统计值的数据
     */
    getIndicatorsData = () => {
        /**
         * 总数
         */
        WorkorderApi.indicators({handlerId: getUser()?.id}).then(response => {
            this.setState({
                workorderTotal: response.data,
                totalFlag: false
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                receiveWorkorder: "加载失败",
                loadingReceive: false
            });
        });
        /**
         * 待领取
         */
        WorkorderApi.indicators({handlerId: getUser()?.id, progress: 5}).then(response => {
            this.setState({
                receiveFlag: false,
                workorderReceive: response.data
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                receiveWorkorder: "加载失败",
                loadingReceive: false
            });
        });
        /**
         * 待处理
         */
        WorkorderApi.indicators({handlerId: getUser()?.id, progress: 9}).then(response => {
            this.setState({
                handleFlag: false,
                workorderHandle: response.data
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                receiveWorkorder: "加载失败",
                loadingReceive: false
            });
        });
        /**
         * 处理中
         */
        WorkorderApi.indicators({handlerId: getUser()?.id, progress: 13}).then(response => {
            this.setState({
                handleFlaging: false,
                workorderHandleing: response.data
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                receiveWorkorder: "加载失败",
                loadingReceive: false
            });
        });
        /**
         * 挂起中
         */
        WorkorderApi.indicators({handlerId: getUser()?.id, progress: 14}).then(response => {
            this.setState({
                hangFlag: false,
                workorderHang: response.data
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                receiveWorkorder: "加载失败",
                loadingReceive: false
            });
        });
        /**
         * 已完成
         */
        WorkorderApi.indicators({handlerId: getUser()?.id, progress: 17}).then(response => {
            this.setState({
                finishFlag: false,
                workorderFinish: response.data
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                receiveWorkorder: "加载失败",
                loadingReceive: false
            });
        });
    };

    /**
     * 统计值
     * @returns {JSX.Element}
     */
    containerContentRender = () => {
        return <StatisticCard.Group style={{textAlign: "center"}}>
            <Row gutter={[8, 8]}>
                <Col xs={12} lg={5}>
                    <StatisticCard
                        loading={this.state.totalFlag}
                        bordered
                        statistic={{
                            title: "处理工单总数",
                            value: this.state.workorderTotal
                        }}
                    />
                </Col>
                {/*<Col xs={12} lg={4}>*/}
                {/*    <StatisticCard*/}
                {/*        loading={this.state.receiveFlag}*/}
                {/*        bordered*/}
                {/*        statistic={{*/}
                {/*            title: "待领取",*/}
                {/*            value: this.state.workorderReceive*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</Col>*/}
                <Col xs={12} lg={5}>
                    <StatisticCard
                        loading={this.state.handleFlag}
                        bordered
                        statistic={{
                            title: "待处理",
                            value: this.state.workorderHandle
                        }}
                    />
                </Col>
                <Col xs={12} lg={5}>
                    <StatisticCard
                        loading={this.state.handleFlaging}
                        bordered
                        statistic={{
                            title: "处理中",
                            value: this.state.workorderHandleing
                        }}
                    />
                </Col>
                <Col xs={12} lg={5}>
                    <StatisticCard
                        loading={this.state.hangFlag}
                        bordered
                        statistic={{
                            title: "挂起中",
                            value: this.state.workorderHang
                        }}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StatisticCard
                        loading={this.state.finishFlag}
                        bordered
                        statistic={{
                            title: "已完成",
                            value: this.state.workorderFinish
                        }}
                    />
                </Col>
            </Row>
        </StatisticCard.Group>;
    };

    render() {
        return <PageContainer content={this.containerContentRender()} header={{title: "我处理的工单", breadcrumb: {}}}>
            <ProTable
                options={{reload: true, setting: true, density: true}}
                defaultSize="small"
                rowKey="id"
                columnsState={this.state.columnStateMap}
                onColumnsStateChange={this.onColumnStateMapChange}
                onLoadingChange={this.onLoadingChange}
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                request={this.requestPage}
                toolBarRender={this.renderToolbar}/>

            <UpdateSmpWorkorderDrawer onSuccess={this.reload} ref={this.editSmpRef}/>
            <UpdateOpsWorkorderDrawer onSuccess={this.reload} ref={this.editOpsRef}/>
            <DeleteWorkorderModal key="delete" onSuccess={this.reload} ref={this.deleteRef}/>
            <PreviewSmpWorkorderDrawer key="preview" onSuccess={this.reload} ref={this.previewSmpRef}/>
            <PreviewOpsWorkorderDrawer key="previewOps" onSuccess={this.reload} ref={this.previewOpsRef}/>
            <HangUpWorkorderDialog key="hang" ref={this.hangRef} onSuccess={this.reload}/>
            <ContinueWorkorderDialog key="continue" ref={this.continueRef} onSuccess={this.reload}/>
        </PageContainer>;
    }
}
