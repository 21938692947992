
/**
 * 待团队领取工单
 *
 * @description
 *
 * @version 1.0.0
 */

import React from "react";
import {Button, DatePicker, Space, Tooltip, Descriptions} from "antd";
import {DeleteOutlined, EditOutlined, CheckOutlined} from "@ant-design/icons";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import SecurityInterceptor, {getUser, hasAnyRole} from "component/SecurityInterceptor";
import AccountApi from "../../../../api/user/account";
import {withNavigation} from "../../../../utils/RouteLink";
import DepartmentApi from "../../../../api/user/department";
import WorkorderApi from "../../../../api/workorder";
import UpdateOpsTeamWorkorderDrawer from "./drawer/UpdateOpsTeamWorkorderDrawer";
import UpdateSmpTeamWorkorderDrawer from "./drawer/UpdateSmpTeamWorkorderDrawer";
import DeleteTeamWorkorderModal from "./modal/DeleteTeamWorkorderModal";
import TeamMemberReceiveModal from "./modal/TeamMemberReceiveModal";

class GrabWorkorder extends React.Component {
    state = {
        accounts:[]
    };

    actionRef;
    deleteAssignedRef = React.createRef();
    dispatchWorkorderRef = React.createRef();
    editWorkorderRef = React.createRef();
    editSmpWorkorderRef = React.createRef();
    teamMemberReceiveRef = React.createRef();
    columns = [
        {
            title: "ID",
            dataIndex: "id",
            editable: false,
            copyable: true,
            // hideInSearch: true
        },
        {
            title: "服务类型",
            dataIndex: "type",
            valueEnum: require("../assets/WorkorderType").WorkorderType
        },
        {
            title: "工单类型",
            dataIndex: "logicType",
            // hideInSearch: true,
            valueEnum: require("../assets/WorkorderLogicType").default
        },
        {
            title: "客户姓名",
            dataIndex: "clientName"
        },
        {
            title: "客户单位",
            dataIndex: "clientDepartmentName",
            hideInSearch: true
        },
        {
            title: "客户电话",
            dataIndex: "clientCellphone"
        },
        {
            title: "处理团队",
            dataIndex: "teamId",
            type: 'select',
            fieldProps: {
                showSearch: true,
                debounce: 500,
                fieldNames: {label: "name", value: "id"},
                treeNodeFilterProp: "name"
            },
            request: () => this.requestAllTeam(),
            render: (text, record) => this.renderColumnText(record.teamId)
        },

        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    componentDidMount() {

    }
    // 查询当前单位所有团队
    requestAllTeam = () => {
        let Teams = JSON.parse(localStorage.getItem("teams"))
        if (Teams.length > 0) {
            const accounts = Teams.map(({ name, id }) => ({ label: name, value: id }));
            this.setState({accounts})

            // 搜索条件
            return Teams
        }

        return []
    };

    //渲染列操作项
    renderColumnText = (id) => {
        let Teams = JSON.parse(localStorage.getItem("teams"))
        if (Teams) {
            return Teams.find(item => item.id === id)?.name;
        }
        return "-"
    }
//
    renderOfColumnButEngineer = (entity) => {
        return <SecurityInterceptor role={["admin","engineer"]}>
            <Space>
                <Tooltip title="接单" >
                    <Button icon size="small" onClick={() => {
                        this.teamMemberReceiveRef.current.show(entity);
                    }}>
                        <CheckOutlined />
                    </Button>
                </Tooltip>
                <Tooltip title="编辑" >
                    <Button icon size="small" onClick={() => {
                        entity.logicType === "smp" ? this.editSmpWorkorderRef.current.show(entity.id) :
                            this.editWorkorderRef.current.show(entity.id);
                    }}>
                        <EditOutlined/>
                    </Button>
                </Tooltip>
                <Tooltip title="删除">
                    <Button danger icon size="small" onClick={() => this.deleteAssignedRef.current.show(entity.id)}>
                        <DeleteOutlined/>
                    </Button>
                </Tooltip>
            </Space>
        </SecurityInterceptor>;
    };

    renderOfColumnOther = (entity) => {
        return <SecurityInterceptor role={["customer-service","user-department-manager"]}>
            <Space>

                <Tooltip title="编辑" >
                    <Button icon size="small" onClick={() => {
                        entity.logicType === "smp" ? this.editSmpWorkorderRef.current.show(entity.id) :
                            this.editWorkorderRef.current.show(entity.id);
                    }}>
                        <EditOutlined/>
                    </Button>
                </Tooltip>
                <Tooltip title="删除">
                    <Button danger icon size="small" onClick={() => this.deleteAssignedRef.current.show(entity.id)}>
                        <DeleteOutlined/>
                    </Button>
                </Tooltip>
            </Space>
        </SecurityInterceptor>;
    };
    renderColumnOperations = (_, entity) => {
        return hasAnyRole(["admin","engineer"]) ? this.renderOfColumnButEngineer(entity) : this.renderOfColumnOther(entity);
    };

    dialog = (id, ref) => ref.current.show(id);

    /**
     * 请求表格数据
     * @param params
     */
    requestPage = params => {
        params.converted = 0
        let teamIds = localStorage.getItem("teamids")
        if(teamIds){
            return WorkorderApi.findTeamDispatch(params,teamIds).then(response => {
                return {data: response.data.records, success: true, total: response.data.total};
            }).catch(error => {
                console.error("Find workorder page failed", error);
                return {data: [], success: true, total: 0};
            });
        }
        return {data: [], success: true, total: 0};
    };

    reload = () => this.actionRef.reload();

    onColumnStateMapChange = columnStateMap => this.setState({columnStateMap});

    render() {
        return <PageContainer
            header={{title: "待团队成员接收", breadcrumb: {}}}
        >
            <ProTable
                options={{reload: true, setting: true, density: true}}
                defaultSize="small"
                rowKey="id"
                onColumnsStateChange={this.onColumnStateMapChange}
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                pagination={{
                    pageSize: 10,
                    onChange: (page) => console.log(page),
                }}
                request={this.requestPage}/>
            <UpdateOpsTeamWorkorderDrawer onSuccess={this.reload} ref={this.editWorkorderRef}/>
            <UpdateSmpTeamWorkorderDrawer onSuccess={this.reload} ref={this.editSmpWorkorderRef}/>
            <DeleteTeamWorkorderModal onSuccess={this.reload} ref={this.deleteAssignedRef}/>
            <TeamMemberReceiveModal onSuccess={this.reload} ref={this.teamMemberReceiveRef}/>
        </PageContainer>;
    }
}

export default withNavigation(GrabWorkorder);
