import React, {useEffect, useRef, useState} from "react";
import {ProConfigProvider, ProLayout} from "@ant-design/pro-components";
import {Badge, Button, message, Tour} from "antd";
import {Outlet} from "react-router";
import {useNavigate} from "react-router-dom";

import {renderMenuItem} from "route";
import UserLabel from "component/user/UserLabel";
import WorkorderApi from "api/workorder";

import style from "./index.module.css";
import moon from "asset/moon.svg"
import sun from "asset/sun.svg"

import "../../editor.css"
import EventBus from "../../utils/eventBus";
import AccountApi from "../../api/user/account";
import {getUser} from "../../component/SecurityInterceptor";
import DepartmentApi from "../../api/user/department";

const Home = () => {

    const darkRef = useRef(null)

    // 路由
    const navigate = useNavigate();

    // 主题
    const [theme, setTheme] = useState(JSON.parse(localStorage.getItem("theme")) ?? false);

    const [open, setOpen] = useState(false);

    const [repairCount, setRepairCount] = useState(0);
    const [dispatchCount, setDispatchCount] = useState(0);
    const [teamhCount, setTeamhCount] = useState(0);

    //钩子
    useEffect(() => {
        if (!localStorage.getItem("User") || !localStorage.getItem("Token")) {
            message.error("暂未登录!");
            navigate("/");
        }

        requestRepairCount();
        requestDispatchCount();
        UserInfo()

        setInterval(() => {
            requestRepairCount();
            requestDispatchCount();
            UserInfo();
        }, 10000);
        setInterval(() => {
            refreshUserInfo();
        }, 60000)
    }, []);

    /**
     * 当前团队列表id
     */
    const UserInfo = () => {
        let params = {
            converted : 0,
            accountId: getUser().id,
            departmentId: getUser().departments[0].id,
            pageSize: 999
        }
        DepartmentApi.getTeamList(params).then(res => {
            let teamIds = ''
            res.data.records.forEach(item => {
                teamIds += '&teamIds=' + item.id
            })
            localStorage.setItem("teams", JSON.stringify(res.data.records))
            localStorage.setItem("teamids",teamIds)
            requesTeamhCount(teamIds)
        })
    }

    /**
     * 获取报修池工单数
     */
    const requestRepairCount = () => {
        WorkorderApi.repairCount().then(res => {
            setRepairCount(res.data)
        });
    };

    /**
     * 获取待指派工单数
     */
    const requestDispatchCount = () => {
        WorkorderApi.dispatchCount().then(res => {
            setDispatchCount(res.data)
        });
    };
    const requesTeamhCount = (teamIds) => {

        WorkorderApi.teamDispatchCount(teamIds).then(res => {

            let count = 0
            res.data.forEach(item => {
                count += item.count
            })
            setTeamhCount(count)
        });

    };

    /**
     * menuItem 点击事件
     * @param item
     */
    const onMenuItemClick = item => {
        navigate(item.path);
    };

    /**
     * 主题切换
     * @param value
     */
    const changeTheme = (value) => {
        setTheme(value);
        localStorage.setItem("theme", JSON.stringify(value))
    }

    /**
     * 渲染头部内容
     * @returns {JSX.Element}
     */
    const renderHeader = () => <div style={{color: theme ? "#fff" : "#000", fontWeight: "bold"}}>信创一站式服务平台</div>

    /**
     * 刷新用户信息
     */
    const refreshUserInfo = () => {
        AccountApi.findUserInfo({params: {accountId: getUser().id}}).then(res => {
            res.data.permissions = ["*:*"];
            if (res.data.roles.length > 0) {
                res.data.roles = res.data.roles.map(item => item.identifier)
            }
            localStorage.setItem("User", JSON.stringify(res.data));
        }).catch(err => {
            console.error("refresh user info failed", err)
        })
    }

    /**
     * 渲染项
     * @param item
     * @param dom
     * @returns {JSX.Element}
     */

    const menuItemRender = (item, dom) => {
        // 报修池
        if (!!item.tag && item.tag === "repair") {
            return <span key={item.path} onClick={() => onMenuItemClick(item)}
                      className={theme ? style.badgeColorDark : style.badgeColor}>
                <Badge count={repairCount} offset={[20, 6]}>{dom}</Badge></span>;
        }
        // 待指派
        if (!!item.tag && item.tag === "dispatch") {
            return <span key={item.path} onClick={() => onMenuItemClick(item)}
                      className={theme ? style.badgeColorDark : style.badgeColor}>
                <Badge count={dispatchCount} offset={[20, 6]}>{dom}</Badge></span>;
        }
        // 团队待领取
        if (!!item.tag && item.tag === "team") {
            return <span key={item.path} onClick={() => onMenuItemClick(item)}
                      className={theme ? style.badgeColorDark : style.badgeColor}>
                <Badge count={teamhCount} offset={[20, 6]}>{dom}</Badge></span>;
        }
        return <span key={item.path} onClick={() => onMenuItemClick(item)}>{dom}</span>;
    };

    /**
     * 主题切换
     * @returns {JSX.Element}
     */
    const renderThemeSwitch = () => {
        return theme ? <img src={moon} style={{width: 20, cursor: "pointer", marginBottom: 10}}
                            onClick={() => {
                                changeTheme(false);
                                EventBus.emit("updateMapTheme", {value: "amap://styles/normal"})
                            }} alt="."/> :
            <img src={sun} style={{width: 20, cursor: "pointer", marginBottom: 10}} onClick={() => {
                changeTheme(true);
                EventBus.emit("updateMapTheme", {value: "amap://styles/darkblue"})
            }} alt="."/>
    }

    /**
     * 渲染用户信息
     * @returns {JSX.Element}
     */
    const rightContentRender = () => <>
        <UserLabel/>
        {/*{renderThemeSwitch()}*/}
    </>

    const steps = [
        {
            title: 'Upload File',
            description: 'Put your files here.',
            cover: (
                <img
                    alt="tour.png"
                    src="https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png"
                />
            ),
            target: () => darkRef.current,
        }
    ];

    const renderLogo = () => <img src={require("../../asset/home_logo.png")} alt="" style={{height: "55px", transform: "translateY(-3px)"}}/>

    return (
        <ProConfigProvider dark={theme}>
            <ProLayout
                fixSiderbar
                layout='mix'
                logo={renderLogo()}
                title=""
                theme={theme}
                menuHeaderRender={false}
                menuItemRender={(item, dom) => menuItemRender(item, dom)}
                menu={{request: () => renderMenuItem(), collapsedShowGroupTitle: true}}
                avatarProps={{render: rightContentRender}}>
                <Outlet/>
            </ProLayout>
            <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>
        </ProConfigProvider>
    );
};

export default Home;
