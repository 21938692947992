import React from "react";
import moment from "moment";
import {Button, Cascader, Col, DatePicker, Dropdown, Menu, Row, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {PageContainer, ProTable, StatisticCard} from "@ant-design/pro-components";

import SecurityInterceptor, {getUser, hasAnyRole, isAdminDepartment} from "component/SecurityInterceptor";
import WorkorderApi from "../../../../api/workorder";
import workorderInfoApi from "../../../../api/workorderInfo";

import CreateOpsUnassignedWorkorderDrawer from "../unassigned/drawer/CreateOpsUnassignedWorkorderDrawer";
import CreateSmpUnassignedWorkorderDrawer from "../unassigned/drawer/CreateSmpUnassignedWorkorderDrawer";

import PreviewSmpWorkorderDrawer from "./drawer/PreviewSmpWorkorderDrawer";
import PreviewOpsWorkorderDrawer from "./drawer/PreviewOpsWorkorderDrawer";

import UpdateSmpWorkorderDrawer from "./drawer/UpdateSmpWorkorderDrawer";
import UpdateOpsWorkorderDrawer from "./drawer/UpdateOpsWorkorderDrawer";

import DeleteWorkorderModal from "./modal/DeleteWorkorderModal";

import ExportOpsWorkorderAsExcelModal from "./modal/export/ExportOpsWorkorderAsExcelModal";
import ExportOpsWorkorderAsWordModal from "./modal/export/ExportOpsWorkorderAsWordModal";
import ExportSmpWorkorderAsExcel from "./modal/export/ExportSmpWorkorderAsExcelModal";
import ExportSmpWorkorderAsWordModal from "./modal/export/ExportSmpWorkorderAsWordModal";
import ExportWorkorderAsExcelModal from "./modal/export/ExportWorkorderAsExcelModal";
import ExportWorkorderAsWordModal from "./modal/export/ExportWorkorderAsWordModal";
import progress from "../assets/WorkorderProgress";

import {withNavigation} from "utils/RouteLink";
import isDevEnvironment from "../../../../utils/isDevEnvironment";
import {TimeoutWorkorder} from "../assets/WorkorderType";
import DictApi from "../../../../api/dict";
import EnumDict from "../../../../enum/dict";

/**
 * 所有工单
 */
class Workorders extends React.Component {

    state = {
        columnStateMap: {
            priority: {
                show: false
            },
            treeData: [],
            appointmentTime: {
                show: false
            },
            deadLine: {
                show: false
            }
        },
        totalFlag: true,
        receiveFlag: true,
        handleFlag: true,
        handleFlaging: true,
        hangFlag: true,
        rejectFlag: true,
        finishFlag: true,
        workorderTotal: 0,
        workorderReceive: 0,
        workorderHandle: 0,
        workorderHandleing: 0,
        workorderHang: 0,
        workorderReject: 0,
        workorderFinish: 0,
        workorderTimeOut: 0
    };

    editSmpRef = React.createRef();
    editOpsRef = React.createRef();
    deleteRef = React.createRef();
    previewSmpRef = React.createRef();
    previewOpsRef = React.createRef();
    /**
     * AntdPro Table ActionRef
     */
    actionRef = null;

    componentDidMount() {
        this.getIndicatorsData();
    }

    componentDidUpdate() {
        if (!!this.props.location?.params && this.props.location?.params?.reload === 1) {
            this.props.location.params.reload = 0;
            this.reload();
        }
    }

    /**
     * 表格的操作
     */
    renderColumnOperations = (_, entity) => {
        return entity.logicType === "smp" ? this.renderOfColumnOperationWhenSmp(entity) : this.renderOfColumnOperationWhenOps(entity);
    };

    renderOfColumnOperationWhenSmp = (entity) => {
        return <Space>
            <Tooltip title="查看">
                <Button icon size="small" onClick={() => this.dialog(entity.id, this.previewSmpRef)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
            <SecurityInterceptor role={["admin", "customer-service"]}>
                <Tooltip title="编辑">
                    <Button icon size="small"
                            onClick={() => this.dialog(entity.id, this.editSmpRef)}
                            disabled={entity.progress === 13 || entity.progress === 17}>
                        <EditOutlined/>
                    </Button>
                </Tooltip>
            </SecurityInterceptor>
            <SecurityInterceptor role={["admin", "customer-service"]}>
                <Tooltip title="删除">
                    <Button danger icon size="small"
                            onClick={() => this.dialog(entity.id, this.deleteRef)}
                            disabled={entity.progress === 13 || entity.progress === 17}>
                        <DeleteOutlined/>
                    </Button>
                </Tooltip>
            </SecurityInterceptor>
        </Space>;
    };

    renderOfColumnOperationWhenOps = (entity) => {
        return <Space>
            <Tooltip title="查看">
                <Button icon size="small" onClick={() => this.dialog(entity.id, this.previewOpsRef)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
            <SecurityInterceptor role={["admin", "customer-service"]}>
                <Tooltip title="编辑">
                    <Button icon size="small" onClick={() => this.dialog(entity.id, this.editOpsRef)}
                            disabled={entity.progress === 13 || entity.progress === 17}>
                        <EditOutlined/>
                    </Button>
                </Tooltip>
            </SecurityInterceptor>
            <SecurityInterceptor role={["admin", "customer-service"]}>
                <Tooltip title="删除">
                    <Button danger icon size="small" onClick={() => this.dialog(entity.id, this.deleteRef)}
                            disabled={entity.progress === 13 || entity.progress === 17}>
                        <DeleteOutlined/>
                    </Button>
                </Tooltip>
            </SecurityInterceptor>

        </Space>;
    };

    dialog = (id, ref) => ref.current.show(id);

    openInspect = (id) => {
        this.dialog(id, this.previewSmpRef)
    };
    /**
     * AntdPro Table Columns
     */
    columns = [
        {
            title: "ID",
            dataIndex: "id",
            render: (node, entity) => {
                return entity.logicType === "smp" ?
                    <Button type="link" danger={entity.delay === 1 }
                            size="small"
                            onClick={() => this.dialog(entity.id, this.previewSmpRef)}>{entity.id}</Button> :
                    <Button type="link" danger={entity.delay === 1 }
                            size="small"
                            onClick={() => this.dialog(entity.id, this.previewOpsRef)}>{entity.id}</Button>;
            }
            // render: (node, entity) => {
            //     console.log(entity.delay)
            //     let time = moment(entity.deadLine).format("YYYYMMDD");
            //     let nowTime = moment().locale("zh-cn").format("YYYYMMDD");
            //     let flag;
            //     if (nowTime - time > 0 && entity.progress === 5) {
            //         flag = true;
            //     }
            //     return entity.logicType === "smp" ?
            //         <Button type="link" danger={entity.progress === 0 || flag}
            //                 size="small"
            //                 onClick={() => this.dialog(entity.id, this.previewSmpRef)}>{entity.id}</Button> :
            //         <Button type="link" danger={entity.progress === 0 || flag}
            //                 size="small"
            //                 onClick={() => this.dialog(entity.id, this.previewOpsRef)}>{entity.id}</Button>;
            // }
        },

        {
            title: "处理进度",
            align: 'center',
            dataIndex: "progress",
            initialValue: !!this.props.location?.state && !!this.props.location?.state?.progress ? this.props.location.state.progress : "",
            valueEnum: require("../assets/WorkorderProgress").default
        },
        {
            title: "服务类型",
            dataIndex: "type",
            valueEnum: require("../assets/WorkorderType").WorkorderType
        },
        {
            title: "工单类型",
            dataIndex: "logicType",
            // hideInSearch: true,
            valueEnum: require("../assets/WorkorderLogicType").default
        },
        {
            title: "客户姓名",
            dataIndex: "clientName"
        },
        {
            title: "客户电话",
            dataIndex: "clientCellphone",

        },
        {
            title: "填单人",
            dataIndex: "creatorName"
        },
        {
            title: "处理人",
            dataIndex: "handlerName"
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "计划完成时间",
            dataIndex: "deadLine",
            key: "deadLine",
            hideInSearch: true
        },
        {
            title: "预约时间",
            dataIndex: "appointmentTime",
            key: "appointmentTime",
            hideInSearch: true
        },
        {
            title: "优先级",
            align: 'center',
            dataIndex: "priority",
            key: "priority",
            valueEnum: require("../assets/WorkorderPriority").default
        },
        {
            title: "满意度",
            align: 'center',
            dataIndex: "feedbackScore",
            hideInTable: !hasAnyRole(["customer-service", "admin"]),
            hideInSearch: !hasAnyRole(["customer-service", "admin"]),
            valueEnum: require("../assets/WorkorderFeedbackType").default
        },
        {
            title: "所在区县",
            key: "locate",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps}) => {
                return (
                    <Cascader options={require("asset/location.json")} {...fieldProps}/>
                );
            }
        },
        {
            title: "客户单位",
            key: "clientDepartmentName",
            hideInTable: true
        },
        {
            title: "时间选择",
            key: "timer",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps}) => {
                return (
                    <DatePicker.RangePicker {...fieldProps} inputReadOnly/>
                );
            }
        },
        // {
        //     title: "异常状态",
        //     dataIndex: " delayed",
        //     hideInSearch: false,
        //     hideInTable: true,
        //     initialValue: !!this.props.location?.state && !!this.props.location?.state?.delayed ? this.props.location.state.delayed : "",
        //     valueEnum: require("../assets/WorkorderType").TimeoutWorkorder
        // },

        {
            title: "异常状态",
            formItemProps: {name: "abnormalStatus"},
            valueType: "select",
            initialValue: this.props.location?.state?.abnormalStatus,
            hideInTable: true,
            fieldProps: {
                showSearch: true,
                debounce: 500,
                mode: 'multiple',
                maxTagCount: 'responsive',
            },
            request: () => {
                return DictApi.list({code : EnumDict.ABNORMAL_STATUS}).then(res => {
                    let selectData = [];
                    res.data.forEach(item => {
                        selectData.push({label: item.value, value: item.id});
                    });
                    return selectData;
                }).catch(() => [])
            },
        },

        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    /**
     * 渲染发起运维工单按钮
     * @returns {JSX.Element|null}
     */
    renderCreateOpsWorkorderBtn = () => {
        if (isDevEnvironment) {
            return <CreateOpsUnassignedWorkorderDrawer key="createSmp" onSuccess={this.reload}/>
        } else {
            // 2022/09/14 为俩用户专门添加发起运维工单权限
            return getUser().departments[0].id === "155562326307770368" || ["168955227586826240", "264588201702854656"].some(item => item === getUser().id) ?
                <CreateOpsUnassignedWorkorderDrawer key="createSmp" onSuccess={this.reload}/> : null
        }

    }

    /**
     * 导出与发起工单操作按钮
     */
    renderToolbar = () => {
        const userMenu = () => {
            return <Menu>
                <Menu.SubMenu key="exportOps" title="导出运维">
                    <Menu.Item key="smpExcel">
                        <ExportOpsWorkorderAsExcelModal onSuccess={this.reload}/>
                    </Menu.Item>
                    <Menu.Item key="smpWord">
                        <ExportOpsWorkorderAsWordModal onSuccess={this.reload}/>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="exportSmp" title="导出信创">
                    <Menu.Item key="opsExcel">
                        <ExportSmpWorkorderAsExcel onSuccess={this.reload}/>
                    </Menu.Item>
                    <Menu.Item key="opsWord">
                        <ExportSmpWorkorderAsWordModal onSuccess={this.reload}/>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="exportAll" title="导出所有">
                    <Menu.Item key="opsAndSmpExcel">
                        <ExportWorkorderAsExcelModal onSuccess={this.reload}/>
                    </Menu.Item>
                    <Menu.Item key="opsAndSmpWord">
                        <ExportWorkorderAsWordModal onSuccess={this.reload}/>
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>;
        };

        return [
            hasAnyRole(["admin", "customer-service"]) ? <>
                <Dropdown overlay={userMenu()} arrow trigger={["click"]} key="1">
                    <Button>导出</Button>
                </Dropdown>
                <CreateOpsUnassignedWorkorderDrawer key="createOps" onSuccess={this.reload}/>,
                <CreateSmpUnassignedWorkorderDrawer key="createSmp" onSuccess={this.reload} onInspect={this.openInspect}/>
            </> : null,
            // this.renderCreateOpsWorkorderBtn(), // 权限 2022/09/14 为俩用户专门添加发起运维工单权限
            // <CreateOpsUnassignedWorkorderDrawer key="createOps" onSuccess={this.reload}/>, // 发起运维工单入口
            // <CreateSmpUnassignedWorkorderDrawer key="createSmp" onSuccess={this.reload} onInspect={this.openInspect}/> //  发起信创工单入口
        ]
    };

    /**
     * 请求表格数据
     */
    requestPage = (params) => {
        // params.logicType = "smp";
        if (!!params.locate) {
            params.province = params.locate[0];
            params.city = params.locate[1];
            params.county = params.locate[2];
            params.locate = undefined;
        }
        if (!!params.timer) {
            params.startDate = moment(params.timer[0]).format("YYYY-MM-DD");
            params.endDate = moment(params.timer[1]).format("YYYY-MM-DD");
            params.timer = undefined;
        }
        if (hasAnyRole(["admin"]) || (isAdminDepartment() && hasAnyRole(["customer-service"]))) {
            return WorkorderApi.page(params).then(response => {
                return {data: response.data.records, success: true, total: response.data.total};
            }).catch(error => {
                console.warn("Find workorder page failed", error);
                return {data: [], success: true, total: 0};
            });
        } else {
            params.customerDepartmentId = getUser().departments[0].id;
            return WorkorderApi.page(params).then(response => {
                return {data: response.data.records, success: true, total: response.data.total};
            }).catch(error => {
                console.warn("Find workorder page failed", error);
                return {data: [], success: true, total: 0};
            });
        }
    };

    reload = () => {
        this.getIndicatorsData();
        this.actionRef.reload();
    };

    onLoadingChange = () => this.getIndicatorsData();

    onColumnStateMapChange = columnStateMap => this.setState({columnStateMap});

    /**
     * 请求统计值数据
     */
    getIndicatorsData = () => {
        /**
         * 总数
         */
        WorkorderApi.indicators({}).then(response => {
            this.setState({
                workorderTotal: response.data,
                totalFlag: false
            });
        }).catch(error => {
            console.error("Get workorder total filed", error);
            this.setState({
                finishFlag: false,
                workorderTimeOut: "加载失败！"
            });
        });
        /**
         * 待领取
         */
        WorkorderApi.indicators({progress: 5}).then(response => {
            this.setState({
                receiveFlag: false,
                workorderReceive: response.data
            });
        }).catch(error => {
            console.error("Get workorder receive filed", error);
            this.setState({
                finishFlag: false,
                workorderTimeOut: "加载失败！"
            });
        });
        /**
         * 待处理
         */
        WorkorderApi.indicators({progress: 9}).then(response => {
            this.setState({
                handleFlag: false,
                workorderHandle: response.data
            });
        }).catch(error => {
            console.error("Get workorder hangle filed", error);
            this.setState({
                finishFlag: false,
                workorderTimeOut: "加载失败！"
            });
        });
        /**
         * 处理中
         */
        WorkorderApi.indicators({progress: 13}).then(response => {
            this.setState({
                handleFlaging: false,
                workorderHandleing: response.data
            });
        }).catch(error => {
            console.error("Get workorder handleing filed", error);
            this.setState({
                finishFlag: false,
                workorderTimeOut: "加载失败！"
            });
        });
        /**
         * 挂起中
         */
        WorkorderApi.indicators({progress: 14}).then(response => {
            this.setState({
                hangFlag: false,
                workorderHang: response.data
            });
        }).catch(error => {
            console.error("Get workorder hang filed", error);
            this.setState({
                finishFlag: false,
                workorderTimeOut: "加载失败！"
            });
        });
        /**
         * 驳回
         */
        WorkorderApi.indicators({progress: 0}).then(response => {
            this.setState({
                rejectFlag: false,
                workorderReject: response.data
            });
        }).catch(error => {
            console.error("Get workorder reject filed", error);
            this.setState({
                finishFlag: false,
                workorderTimeOut: "加载失败！"
            });
        });
        /**
         * 已完成
         */
        WorkorderApi.indicators({progress: 17}).then(response => {
            this.setState({
                finishFlag: false,
                workorderFinish: response.data
            });
        }).catch(error => {
            console.error("Get workorder finish filed", error);
            this.setState({
                finishFlag: false,
                workorderTimeOut: "加载失败！"
            });
        });
        /**
         * 超期
         */
        workorderInfoApi.timeoutNumber().then(response => {
            this.setState({
                finishFlag: false,
                workorderTimeOut: response.data
            });
        }).catch(error => {
            console.error("Get workorder time out filed", error);
            this.setState({
                finishFlag: false,
                workorderTimeOut: "加载失败！"
            });
        });
    };

    /**
     * 统计值
     */
    containerContentRender = () => <StatisticCard.Group style={{textAlign: "center"}}>
        <Row gutter={[8, 8]}>
            <Col xs={12} lg={3}>
                <StatisticCard bordered
                               loading={this.state.totalFlag}
                               statistic={{
                                   title: "所有工单总数",
                                   value: this.state.workorderTotal
                               }}
                />
            </Col>
            <Col xs={12} lg={3}>
                <StatisticCard bordered
                               loading={this.state.receiveFlag}
                               statistic={{
                                   title: "待领取",
                                   value: this.state.workorderReceive
                               }}
                />
            </Col>
            <Col xs={12} lg={3}>
                <StatisticCard bordered
                               loading={this.state.handleFlag}
                               statistic={{
                                   title: "待处理",
                                   value: this.state.workorderHandle
                               }}
                />
            </Col>
            <Col xs={12} lg={3}>
                <StatisticCard bordered
                               loading={this.state.handleFlaging}
                               statistic={{
                                   title: "处理中",
                                   value: this.state.workorderHandleing
                               }}
                />
            </Col>
            <Col xs={12} lg={3}>
                <StatisticCard
                    loading={this.state.hangFlag}
                    bordered
                    statistic={{
                        title: "挂起中",
                        value: this.state.workorderHang
                    }}
                />
            </Col>
            <Col xs={12} lg={3}>
                <StatisticCard bordered
                               loading={this.state.rejectFlag}
                               statistic={{
                                   title: "已驳回",
                                   value: this.state.workorderReject
                               }}
                />
            </Col>
            <Col xs={12} lg={3}>
                <StatisticCard bordered
                               loading={this.state.finishFlag}
                               statistic={{
                                   title: "已完成",
                                   value: this.state.workorderFinish
                               }}
                />
            </Col>
            <Col xs={12} lg={3}>
                <StatisticCard bordered
                               loading={this.state.finishFlag}
                               statistic={{
                                   title: "已超时",
                                   status: "error",
                                   value: this.state.workorderTimeOut
                               }}/>
            </Col>
        </Row>
    </StatisticCard.Group>;

    render() {
        return <PageContainer header={{title: "所有工单管理", breadcrumb: {}}} extra={this.renderToolbar()}
                              content={this.containerContentRender()}>
            <ProTable rowKey="id"
                      polling={15000}
                      defaultSize="small"
                      columns={this.columns}
                      columnsState={this.state.columnStateMap}
                      onColumnsStateChange={this.onColumnStateMapChange}
                      actionRef={actionRef => this.actionRef = actionRef}
                      options={{reload: true, setting: true, density: true}}
                      onLoadingChange={this.onLoadingChange}
                      request={this.requestPage}/>

            <UpdateSmpWorkorderDrawer onSuccess={this.reload} ref={this.editSmpRef}/>
            <UpdateOpsWorkorderDrawer onSuccess={this.reload} ref={this.editOpsRef}/>
            <DeleteWorkorderModal onSuccess={this.reload} ref={this.deleteRef}/>
            <PreviewSmpWorkorderDrawer onSuccess={this.reload} ref={this.previewSmpRef}/>
            <PreviewOpsWorkorderDrawer onSuccess={this.reload} ref={this.previewOpsRef}/>
        </PageContainer>;
    }
}

export default withNavigation(Workorders);
