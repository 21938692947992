import {message} from "antd";

// 防抖函数触发时的时间戳字段
let CURRENT_TIME;

/**
 * 判断对象是否为空
 * @param obj 对象
 * @returns {boolean}
 */
export const isObjectEmpty = obj => Reflect.ownKeys(obj).length === 0 && obj.constructor === Object;

/**
 * 判断两个对象是否完全相等
 * @param obj1 第一个对象
 * @param obj2 第二个对象
 * @returns {boolean|this is string[]}
 */
export const isObjectEqual = (obj1, obj2) => {
    const KEY = {OBJECT: "object", ARRAY: "array", FUNCTION: "function"};
    const keys1 = Object.getOwnPropertyNames(obj1);
    const keys2 = Object.getOwnPropertyNames(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    return keys1.every(key => {
        const TYPE = typeof obj1[key];

        if (!!obj1[key] && TYPE === KEY.OBJECT) {
            return isObjectEqual(obj1[key], obj2[key]);
        }

        if (TYPE === KEY.ARRAY) {

            if (obj1[key].length !== obj2[key].length) {
                return false;
            }
            return obj1[key].every((child, index) => {
                const CHILD_TYPE = typeof child;

                if ([KEY.ARRAY].includes(CHILD_TYPE)) {
                    return true;
                }

                if ([KEY.OBJECT, KEY.FUNCTION].includes(CHILD_TYPE)) {
                    const OBJ2_CHILD = obj2[key][index];
                    if (CHILD_TYPE !== typeof OBJ2_CHILD) {
                        return false;
                    }
                    return isObjectEqual(child, OBJ2_CHILD);
                }

                return child === obj2[key][index];

            });
        }

        if (TYPE === KEY.FUNCTION) {
            return obj1[key].toString() === obj2[key].toString();
        }

        return obj1[key] === obj2[key];

    });
};

/**
 * 深拷贝数组或对象
 * @param obj 需要被拷贝的数组或者对象
 * @returns {*[]}
 */
export const arrayOrObjCopy = (obj) => {
    let newObj = obj.constructor === Array ? [] : {};
    if (typeof obj !== "object") {
        return null;
    }
    for (let i in obj) {
        newObj[i] = typeof obj[i] === "object" ? arrayOrObjCopy(obj[i]) : obj[i];
    }
    return newObj;
};

export const buildTree = (list,rootValue) => {
    // 声明一个数组用来接收符合条件的元素
    let arr = []
    // 注意我们在查找时一定不能是写死的条件
    list.forEach(item => {
        //根据id找所有子节点
        if (item.pid === rootValue) {
            // 将找到的父节点追加到数组中
            arr.push(item)
            // 根据父亲的ID找他的孩子
            const children = buildTree(list, item.id)
            // 同时将找到的孩子放到父亲的children树形里
            if (children.length) {
                item.children = children
            }
        }
    })
    // 最后返回操作完的数组
    return arr
}
// 转换 新树形结构
export const treeData = (list) => {
    // 声明一个数组用来接收符合条件的元素
    let arr = []
    // 注意我们在查找时一定不能是写死的条件
    list.forEach(item => {
        //根据id找所有子节点
        if (item.children) {
            let children = treeData(item.children)
            item.children = children
            arr.push({
                value: item.id,
                title: item.value,
                key: item.id,
                children
            })
        }else {
            arr.push({
                value: item.id,
                title: item.value,
                key: item.id,
            })
        }

    })
    // 最后返回操作完的数组
    return arr
}

/**
 * 判断两数组是否有相同元素, true: 存在相同 false：没相同
 * @param arr 第一个数组
 * @param otherArr 第二个数组
 * @returns {boolean}
 */
export const isArrayAnySame = (arr, otherArr) => {
    // 去重合并数组
    const normalArr = [...[...new Set(arr)], ...[...new Set(otherArr)]];
    // 再对合并数组去重
    const setArr = [...new Set(normalArr)];
    return normalArr.length !== setArr.length;
};

/**
 * 判断两个数组是否完全相同
 * @param arr 第一个数组
 * @param otherArr 第二个数组
 * @returns {boolean}
 */
export const isArrayAllSame = (arr, otherArr) => {
    // 去重合并数组
    const normalArr = [...arr, ...otherArr];
    // 再对合并数组去重
    const setArr = [...new Set(normalArr)];
    return normalArr.length === setArr.length * 2;
};

/**
 * 树形结构转JSON数据
 * @param data 数据源
 * @param flatData 存放转换数据数组
 * @param childrenName 子数据字段名称
 * @returns {*[]}
 */
export const treeToJson = (data, flatData = [], childrenName = "children") => {

    if (data.length < 1) {
        return [];
    }

    for (const item of data) {
        if (!item[childrenName]) {
            flatData.push(item);
        } else {
            treeToJson(item[childrenName], flatData, childrenName);
            delete item[childrenName];
            flatData.push(item);
        }
    }

    return flatData;
};

/**
 * JSON数据转树形结构
 * @param data 数据源
 * @param parentId 父id
 * @param flatData 存放转换后的数据数组
 * @returns {*[]}
 */
export const jsonToTree = (data, parentId = "0", flatData = []) => {

    for (const item of data) {
        if (item.parentId === parentId) {
            flatData.push(item);
        }
    }

    for (const item of flatData) {
        item.children = [];
        jsonToTree(data, item.id, item.children);
        if (item.children.length < 1) {
            delete item.children;
        }
    }

    return flatData;

};

/**
 * antd form 表单 错误提示
 * @param ref  表单ref引用
 * @param errData  数组对象  {name:"",errors:[""]}
 */
export const setFormError = (ref, errData = []) => {
    ref.current.setFields(errData);
};

/**
 * 请求错误时 弹出提示
 * @param err 错误信息
 */
export const alertErrorMessage = (err) => {
    if (typeof err === "object") {
        for (const errKey in err.data) {
            message.error(err[errKey]);
        }
    }
    if (typeof err === "string") {
        message.error(err);
    }
};

/**
 * 节流器
 * @param time 触发时间间隔
 */
export const throttleValve = (time = 800) => {
    let nowTime = new Date().getTime();
    if ((nowTime - CURRENT_TIME) >= time || CURRENT_TIME === undefined) {
        CURRENT_TIME = nowTime;
        // 正常逻辑应该返回 true 但为了外面业务代码好写 所以返回false
        return false;
    }
    return true;
};

/**
 * 防抖函数
 * @param fn 被触发函数
 * @param time 防抖时间
 */
export const debounce = (fn, time = 800) => {
    // 掉用节流器 防止函数被重复触发多次
    if (throttleValve(time)) return;
    let timer = null;
    return (function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn();
        }, time);
    })();
};

/**
 * 处理工单处理人数据结构
 *
 * @description 将服务端返回的普通数组转换成 `省 -> 市 -> 县/区 -> 处理人` 的结构
 * @param handlers 服务端响应的处理人数组
 */
export const handleHandlers = handlers => {
    const provinces = {};
    for (const handler of handlers) {
        // 初始化当前用户所在省份
        let province;
        if (!!provinces[handler.province]) {
            province = provinces[handler.province];
        } else {
            province = {
                label: handler.province,
                value: handler.province,
                test: {}
            };
            provinces[handler.province] = province;
        }

        // 初始化当前用户所在市
        let city;
        if (!!province.test[handler.city]) {
            city = province.test[handler.city];
        } else {
            city = {
                label: handler.city,
                value: handler.city,
                test: {}
            };
            province.test[handler.city] = city;
        }

        let county;
        // 初始化当前用户所在区县
        if (!!city.test[handler.county]) {
            county = city.test[handler.county];
        } else {
            county = {
                label: handler.county,
                value: handler.county,
                children: []
            };
            city.test[handler.county] = county;
        }

        county.children.push({
            label: `${handler.name}`,
            value: handler.id
        });
    }
    return provinces;
};

/**
 * 对象转地址参数
 * @param obj 对象
 */
export const objToUrlParams = obj => {
    return Object.keys(obj).map(key => `${key}=${obj[key]}`).join("&");
};