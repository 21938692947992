import React from "react";
import {Button, Col, Drawer, Form, Input, Row, Skeleton, Space, Transfer} from "antd";
import EquipmentTypeApi from "../../../../../api/equipment/type";

import DictApi from "../../../../../api/dict";
import EnumDict from "../../../../../enum/dict";
/**
 * 设备类别鉴别
 * @author xuwei
 */
export default class CategorySettingDrawer extends React.Component {

    state = {
        loading: false,
        drawerWidth: 650,
        show:false,
        transferData: [],
        transferTargetKeys: [],
        managerKeys: [],
        commonTypeData: [],

    };

    draggableRef = React.createRef();
    formRef = React.createRef();
    /**
     * 控制对话框显示隐藏
     */
    show = id => {
        //this.draggableRef.current.show();
        this.setState({show: true}, () => {
            this.requestCommonType()
            this.requestTargetKeys();
        });

    };

    close = () => {
        this.setState({loading: false, show: false});

    };

    /**
     * 控制 loading 加载显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };
    /**
     * 获取设备大类
     */
    requestCommonType = async () => {
        const {data} = await DictApi.list({code : EnumDict.DEVICE_COMMON_TYPE})
        this.setState({
            commonTypeData: data
        }, () => {
            data.forEach(item => {
                if (item.enKey === "dn") {
                    this.requestTransferData(item.id)
                }
            })
         })

    }

    /**
     * 获取所以设备类别
     * @returns {Promise<void>}
     */
    requestTransferData = async (commonType) => {
        const {data} = await EquipmentTypeApi.selectAll({commonType})
        let Data = data.map(item => {
            return {
                id: item.id,
                name: item.name + "_" + item.model
            }
        })
        this.setState({
            transferData: Data,
        }, () => {
            console.log(data)
         });

    };

    requestTargetKeys = async () => {
        const {data} = await EquipmentTypeApi.selectAllMonitoring();
        this.setState({
            transferTargetKeys:data.map(item => String(item.id)),
        });
        return data;
    };

    handleTransferTargetChange = (nextTargetKeys, direction, targetKeys) => {
        direction === "left" ? this.onRemoveLeft(nextTargetKeys, direction, targetKeys) : this.onRemoveRight(nextTargetKeys, direction, targetKeys);
        this.setState({transferTargetKeys: nextTargetKeys});
    };

    onRemoveLeft = (allTargetKeys, direction, targetKeys) => {
        this.setState({managerKeys: [...this.state.managerKeys, ...targetKeys]});
    };

    onRemoveRight = (allTargetKeys, direction, targetKeys) => {
        this.setState({
            managerKeys: this.state.managerKeys.filter(item => {
                return targetKeys.indexOf(item) === -1;
            })
        });
    };

    /**
     * 提交
     */
    handle = () => {
        this.toggleLoading();
        EquipmentTypeApi.updateMonitoring(this.state.transferTargetKeys).then(res => {
            this.props.onSuccess()
            this.toggleLoading();
            this.close();
        }).catch(err => {
            console.log("Find monitoring page failed", err)
            this.close();
        })

    };
    renderDrawerFooter = () => <Row gutter={8} justify="end">
        <Col>
            <Space>
                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                <Button loading={this.state.loading} type="primary" onClick={this.handle}>提交</Button>
            </Space>
        </Col>
    </Row>;

    render() {
        return <Drawer title="设置用户角色"
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       closable={!this.state.loading || !this.state.transferData || !this.state.transferTargetKeys}
                       onClose={this.close}
                       destroyOnClose
                       footer={this.renderDrawerFooter()}>
            <Form ref={this.formRef} onFinish={this.handle} layout="vertical" >
                <Form.Item label="选择设备类别">
                    <Transfer dataSource={this.state.transferData}
                              showSearch
                              operations={["添加", "移除"]}
                              titles={["所有设备类别", "已选设备类别"]}
                              listStyle={{height: "80vh"}}
                              rowKey={record => record.id}
                              targetKeys={this.state.transferTargetKeys}
                              render={item => item.name}
                              onChange={this.handleTransferTargetChange}
                              pagination
                    />
                </Form.Item>
            </Form>
        </Drawer> ;
    }
}
